import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const bodyEl = typeof window !== "undefined" && document.querySelector("body");

const Portal = ({ children }) => {
  const [domReady, setDomReady] = useState(false);
  const portalRef = useRef(null);
  useEffect(() => {
    setDomReady(true);
    bodyEl.style.overflow = "hidden";
    portalRef.current = document?.createElement("div");
    bodyEl?.appendChild(portalRef?.current);

    return () => {
      bodyEl.style.overflow = "unset";
    };
  }, []);
  return domReady
    ? ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        children,
        // A DOM element
        portalRef?.current
      )
    : null;
};

export default Portal;
