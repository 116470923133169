import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import "./index.scss";
import PropTypes from "prop-types";
import NewSpinner from "../newSpinner/newSpinner";
import FullPageLoader from "../fullPageLoader/fullPageLoader";

const PageLoader = (props) => {
  const { children, LoadingState, PageLoaderState, styles, clearTime, normalStyles } = props;
  const [PageLoader, setPageLoader] = useState(PageLoaderState);
  const [Loading, setLoading] = useState(LoadingState);
  useEffect(() => {
    setPageLoader(PageLoaderState);
    setLoading(LoadingState);
    let timer = setTimeout(() => {
      setPageLoader(false);
      setLoading(false);
    }, clearTime);
    return () => {
      clearTimeout(timer);
    };
  }, [LoadingState, PageLoaderState]);
  return (
    <div
      className={"pageLoader_container"}
      style={PageLoader || Loading ? styles : normalStyles}
    >
      {PageLoader &&
        createPortal(
          <FullPageLoader />,
          document.getElementsByTagName("body")[0]
        )}
      {Loading && (
        <div className={"loading_container"}>
          <NewSpinner />
        </div>
      )}
      {children}
    </div>
  );
};

//API
PageLoader.defaultProps = {
  children: null,

  LoadingState: false,

  PageLoaderState: false,

  styles: {},

  clearTime: 60 * 1000,

  normalStyles: {},
};


PageLoader.propTypes = {
  children: PropTypes.node,
  LoadingState: PropTypes.bool,
  PageLoaderState: PropTypes.bool,
  styles: PropTypes.object,
  clearTime: PropTypes.number,
  normalStyles: PropTypes.object,
};
export default PageLoader;
