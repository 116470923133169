import { getPassDetails, createPass, updatePassOwnership, updatePassStatusDate } from '../../../../controllers/PassTicketController';

export const enums = {
  SET_PASS_STATE: { type: 'SET_PASS_STATE', state: 'pass' },
  SET_CREATE_PASS_STATE: { type: 'SET_CREATE_PASS_STATE', state: 'passCreate' },
  SET_UPDATE_PASS_STATE: { type: 'SET_UPDATE_PASS_STATE', state: 'passUpdate' },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchPassState = params => async (dispatch) => {
  try {
    let data = await getPassDetails(params);
    if (data) {
      dispatch(setState(enums.SET_PASS_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchCreatePassState = params => async (dispatch) => {
  try {
    let data = await createPass(params);
    if (data) {
      dispatch(setState(enums.SET_CREATE_PASS_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchUpdatePassOwnershipState = params => async (dispatch) => {
  try {
    let data = await updatePassOwnership(params);
    if (data) {
      dispatch(setState(enums.SET_UPDATE_PASS_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchUpdatePassStatusDateState = params => async (dispatch) => {
  try {
    let data = await updatePassStatusDate(params);
    if (data) {
      dispatch(setState(enums.SET_UPDATE_PASS_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

// state
const passState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  passState,
};
