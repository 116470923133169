import axios from '../../../../utils/axios-interceptor';
import { getAPIHost } from '../../../../utils/helper';
import { getAll } from '../../../../utils/loginSession';
import { getPassDetails } from '../../../../controllers/PassTicketController';

export const enums = {
  SET_PASS_DETAILS_STATE: { type: 'SET_PASS_DETAILS_STATE', state: 'passDetails' },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchState = (type, data = {}) => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const login = getAll(getState());
    if (type.url) {
      const res = await axios({
        headers: login.accessHeader,
        method: 'post',
        url: `${apiUrl}/${type.url}`,
        data: data,
      });
      dispatch(setState(type, res.data));
      return res.data;
    } else {
      dispatch(setState(type, data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchPassDetailsState = params => async (dispatch) => {
  try {
    // const apiUrl = getAPIHost();
    // let res = await axios({
    //   headers: getAccessHeader(getState()),
    //   method: 'GET',
    //   url: `${apiUrl}/getPassDetails`,
    //   params: {
    //     passNo: params.passNo,
    //     loginAda: getADA()
    //   },
    // });

    let data = await getPassDetails(params);
    if (data) {
      dispatch(setState(enums.SET_PASS_DETAILS_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

// state
const passDetailsState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  passDetailsState,
};
