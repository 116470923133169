import { getPassList } from '../../controllers/PassTicketController';

export const enums = {
  SET_PASS_LIST_STATE: { type: 'SET_PASS_LIST_STATE', state: 'passList' },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchPassListState = params => async (dispatch) => {
  try {
    let data = await getPassList(params);
    if (data) {
      dispatch(setState(enums.SET_PASS_LIST_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

// state
const passListState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  passListState,
};