export const contentTypes = {
  multilingualMenu: {
    typeId: "sidemenu",
  },

  footerContent: {
    typeId: "pagefooter_content",
  },

  aboApcContent: {
    typeId: "abo_ui",
  },

  cusCareContent: {
    typeId: "customer_care_ui",
  },

  hccPartnerContent: {
    typeId: "partner_ui",
  }
};
