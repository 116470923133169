import React, { useState } from 'react';

import bn from '../utils/bemnames';

import { Container } from 'reactstrap';
import Breakpoint from "../utils/breakpoint";
const bem = bn.create('content');

const Content = ({ tag: Tag, className, ...restProps }) => {
  const classes = bem.b(className);
  const [breakpoint, setBreakpoint] = useState("xs");
  let isDesktop = breakpoint !== 'xs' && breakpoint !== 'sm';

  return (
    <>
      <Breakpoint onBreakpointChange={setBreakpoint} />
      <Tag className={`${classes} ${isDesktop ? 'content-desktop' : ''}`} {...restProps} />
    </>
  );
};

Content.defaultProps = {
  tag: Container,
};

export default Content;
