// import logo from '../assets/img/logo/AmwayLogo_black.png';
import React, { useState, useEffect } from 'react';
import PopupConfirm from '../components/popupConfirm/popupConfirm';
import { Menu } from './index';
import logOutIcon from '../assets/icon/LogOut.png';
import { Button, Nav, Navbar } from 'reactstrap';
import { MdMenu, MdClose } from 'react-icons/md';
import bn from '../utils/bemnames';
import Cookies from "universal-cookie";
import jwt from "jsonwebtoken";
import Icon from "../components/icon/icon";
import Breakpoint from "../utils/breakpoint";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getTokenType } from '../utils/helper';
import { logoutSession } from '../utils/login';
import { Popper } from '@material-ui/core';
import { useSelector } from "react-redux";

const bem = bn.create('header');
// const helper = require('../utils/helper');

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userID, setUserID] = useState('');

  const [showLogout, setShowLogout] = useState(false);
  const [isLangExpanded, setIsLangExpanded] = useState(false);
  const [isUserExpanded, setIsUserExpanded] = useState(false);
  // const [isLangExpandedClicked, setIsLangExpandedClicked] = useState(false);
  // const [isUserExpandedClicked, setIsUserExpandedClicked] = useState(false);
  // let isLangExpandedClicked = false;
  // let isUserExpandedClicked = false;
  const [breakpoint, setBreakpoint] = useState("xs");
  let isDesktop = breakpoint !== 'xs' && breakpoint !== 'sm';

  const [curLanguage, setCurLanguage] = useState({ langCode: "", langName: "" });
  const [languages, setLanguages] = useState([
    { langCode: "en", langName: "EN" },
    { langCode: "ms", langName: "BM" },
    { langCode: "zh", langName: "中文" },
  ]);
  const currentPath = window.location.hostname;

  const contentStackData = useSelector(state => state?.layoutState?.layout);

  useEffect(() => {
    const cookies = new Cookies();
    const accessToken = cookies.get("accessToken") || '';

    const role = getTokenType(accessToken);
    setUserRole(role);
    getCurLanguage();

    if (role === 'abo' || role === 'apc') {
      setUserID((sessionStorage.getItem('accessToken') || localStorage.getItem('loginFlag') === 'true') ? jwt?.decode(accessToken)?.account?.abo : '');
    } else if (role === 'cuscare') {
      setLanguages([{ langCode: "en", langName: "EN" }]);
    } else if (role === 'hccpartner') {
      setLanguages([{ langCode: "en", langName: "EN" }]);
    }
  }, []);

  // const getRouter = () => {
  //   if (typeof window === "undefined") 
  //     return {};
  //
  //   return window.useRouter && window.useRouter();
  // };

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleActionLogout = () => {
    setShowLogout(false);

    //getRouter().push("/logout");
  };

  const handleActionCancel = () => {
    setShowLogout(false);
  };

  useEffect(() => {
    console.log("Header BreakPoint:", breakpoint);
    console.log("Header Is Desktop:", isDesktop);

    if (isDesktop)
      setIsMenuOpen(false);

  }, [breakpoint]);

  // useEffect(() => {
  //   console.log("T1:");
  //   if (isUserExpandedClicked) {
  //     console.log("T2:");
  //     if (isUserExpanded) {
  //       console.log("T3:");
  //       document.getElementById('root').addEventListener('click', closeAllPopover);
  //     } else {
  //       console.log("T4:");
  //       document.getElementById('root').removeEventListener('click', closeAllPopover);
  //     }
  //   }
  // }, [isUserExpanded]);

  // useEffect(() => {
  //   console.log("TT1:");
  //   if (isLangExpandedClicked) {
  //     console.log("TT2:");
  //     if (isLangExpanded) {
  //       console.log("TT3:");
  //       document.getElementById('root').addEventListener('click', closeAllPopover);
  //     } else {
  //       console.log("TT4:");
  //       document.getElementById('root').removeEventListener('click', closeAllPopover);
  //     }
  //   }
  // }, [isLangExpanded]);

  // const closeAllPopover = e => {
  //   e.preventDefault();
  //   setIsUserExpanded(false);
  //   setIsLangExpanded(false);

  //   document.getElementById('root').removeEventListener('click', closeAllPopover);
  // };

  // User Icon Expanded Function
  const handleUserIconClick = e => {
    e.preventDefault();
    //isUserExpandedClicked = true;
    //isLangExpandedClicked = false;
    // setIsUserExpandedClicked(true);
    // setIsLangExpandedClicked(false);

    setIsUserExpanded(!isUserExpanded);
    setIsLangExpanded(false);
  };

  // Language Icon Expanded Function
  const handleLangIconClick = e => {
    e.preventDefault();
    //isUserExpandedClicked = false;
    //isLangExpandedClicked = true;
    // setIsUserExpandedClicked(false);
    // setIsLangExpandedClicked(true);

    setIsLangExpanded(!isLangExpanded);
    setIsUserExpanded(false);
  };

  const getCurLanguage = () => {
    const language = localStorage.getItem('language') ?? "en";
    let langItem = "";

    switch (language) {
      case "zh":
        langItem = languages.filter(i => i.langCode === "zh");
        break;
      case "en":
        langItem = languages.filter(i => i.langCode === "en");
        break;
      case "ms":
        langItem = languages.filter(i => i.langCode === "ms");
        break;
      default:
        langItem = languages.filter(i => i.langCode === "en");
        break;
    }
    setCurLanguage(langItem[0]);
  };

  const handleLogoutAction = () => {
    if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
      logoutSession();
      const country = localStorage.getItem('country') ?? "my";

      switch (country) {
        case "my":
          window.location.href = "/";
          break;
        case "sg":
          window.location.href = "/";
          break;
        case "bn":
          window.location.href = "/";
          break;
        default:
          window.location.href = "/";
          break;
      }
    } else if (currentPath === 'uat.healthscreening.amway.my' || currentPath === 'healthscreening.amway.my' || currentPath === 'psup.healthscreening.amway.my') {
      logoutSession();
      window.location.href = process.env.REACT_APP_LOGOUT_URL_MY;

    } else if (currentPath === 'uat.healthscreening.amway.com.bn' || currentPath === 'healthscreening.amway.com.bn' || currentPath === 'psup.healthscreening.amway.com.bn') {
      logoutSession();
      window.location.href = process.env.REACT_APP_LOGOUT_URL_BN;

    } else if (currentPath === 'uat.partner.healthscreening.amway.my' || currentPath === 'partner.healthscreening.amway.my' || currentPath === 'psup.partner.healthscreening.amway.my') {
      logoutSession();
      window.location.href = "/login";

    } else if (currentPath === 'uat.partner.healthscreening.amway.com.bn' || currentPath === 'partner.healthscreening.amway.com.bn' || currentPath === 'psup.partner.healthscreening.amway.com.bn') {
      logoutSession();
      window.location.href = "/login";

    }
  };

  // Handle Language's sub menu tab
  const handleLanguageAction = async (id) => {
    setIsLangExpanded(false);
    if (localStorage.getItem('language') !== id) {
      localStorage.setItem('language', id);

      window.location.reload(1);
    }
  };

  return (
    <>
      <Breakpoint onBreakpointChange={setBreakpoint} />
      <div className={bem.b()}>
        <Navbar light expand className={`language-header`} style={{ display: isDesktop ? 'flex' : 'none' }}>
          <Nav navbar className="hr-language-toggle" style={{ padding: '1px 1px' }}>
            <Button
              id="lang_popover"
              className="btn_name"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleLangIconClick}
            >
              <span className="language" title={"language"}>
                {curLanguage.langName}
              </span>
              <div className={`${"expandIcon"} ${isLangExpanded ? "edgeEnd" : ""}`}>
                <Icon name={"dropdown_icon"} />
              </div>
            </Button>
          </Nav>

          <Popper
            id="langPopper"
            open={isLangExpanded && isDesktop}
            anchorEl={document.getElementById('lang_popover')}
            placement="bottom-start"
            style={{ zIndex: 100, minWidth: '140px' }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setIsLangExpanded(false);
              }}
            >
              <ul className="lang_popover_container" onMouseDown={e => e.preventDefault()}>
                {languages.map((item, index) => (
                  <li className={'mbli'} key={index} onClick={() => handleLanguageAction(item.langCode)}>
                    <div className={`${'langText'}`}>
                      {item?.langName}
                    </div>
                  </li>
                ))}
              </ul>
            </ClickAwayListener>
          </Popper>
        </Navbar>

        <Navbar light expand className={`amway-header`}>
          <Nav navbar className="hr-menu-toggle">
            {!isDesktop ? (
              <Button
                id='menu-toggle'
                onClick={handleMenu}
                className="menu-toggle-icon"
                data-tut="menu-icon"
              >
                {!isMenuOpen && <MdMenu size={30} />}
                {isMenuOpen && <MdClose size={30} />}
              </Button>
            ) : null}

            {/* <img src={logo} className="amway-logo" alt="" /> */}
            <div className='svg Amway_logo_svg' />
          </Nav>

          <div className="dropdown_area" style={{ padding: isDesktop ? '0 10px' : '0 1px' }}>
            <Button
              id="user_popover"
              className="btn_name"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleUserIconClick}
            >
              <span className="icon_container">
                <Icon name={"user_icon"} />
              </span>
              {['abo', 'apc'].includes(userRole?.toLowerCase()) && !userID ? (
                null
              ) : (
                <span className="user_name">
                  {userRole?.toLowerCase() === 'abo' ?
                    `${contentStackData?.layout?.header?.abo || 'ABO ID'} ${userID}`
                    : userRole?.toLowerCase() === 'apc' ?
                      `${contentStackData?.layout?.header?.apc || 'APC ID'} ${userID}`
                      : userRole?.toLowerCase() === 'cuscare' ?
                        `${contentStackData?.layout?.header?.amway_cc || 'Amway CC'}`
                        : userRole?.toLowerCase() === 'hccpartner' ?
                          `${contentStackData?.layout?.header?.user_name || 'HCC EMP'}`
                          : ''}
                </span>
              )}
              {isDesktop ? (
                <div className={`${"expandIcon"} ${isUserExpanded ? "edgeEnd" : ""}`}>
                  <Icon name={"dropdown_icon"} />
                </div>
              ) : null}
            </Button>
          </div>

          <Popper
            id="userPopper"
            open={isUserExpanded && isDesktop}
            anchorEl={document.getElementById('user_popover')}
            placement="bottom-end"
            style={{ zIndex: 100, minWidth: '140px' }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setIsUserExpanded(false);
              }}
            >
              {/* <div className="user_popover_container" onClick={handleLogoutAction}>
                <div className={"logout_icon"}>
                  <img src={logOutIcon} />
                </div>
                <div className={"text"} style={{ fontWeight: "600" }}>{"Sign Out"}</div>
              </div> */}
              <ul className="user_popover_container" onMouseDown={e => e.preventDefault()}>
                <li className={'mbli'} onClick={handleLogoutAction}>
                  <div className={"logout_icon"}>
                    <img src={logOutIcon} />
                  </div>
                  <div className={`${'logOutText'}`}>
                    {contentStackData?.menus?.multiLang?.find(obj => obj?.title?.toLowerCase() === "sign out")?.value || 'Sign Out'}
                  </div>
                </li>
              </ul>
            </ClickAwayListener>
          </Popper>
        </Navbar>

        {isMenuOpen && <Menu />}

        <PopupConfirm
          title={contentStackData?.menus?.multiLang?.filter(i => i?.title?.toLowerCase() === 'sign out')?.[0]?.value || 'Sign Out'}
          showPopup={showLogout}
          okBtn={{
            text: `${contentStackData?.menus?.multiLang?.filter(i => i?.title?.toLowerCase() === 'sign out')?.[0]?.sign_out?.confirm_button || 'Confirm'}`,
            action: handleActionLogout,
          }}
          cancelBtn={{
            text: `${contentStackData?.menus?.multiLang?.filter(i => i?.title?.toLowerCase() === 'sign out')?.[0]?.sign_out?.cancel_button || 'Cancel'}`,
            action: handleActionCancel,
          }}
        >
          <span>{contentStackData?.menus?.multiLang?.filter(i => i?.title?.toLowerCase() === 'sign out')?.[0]?.sign_out?.message || 'Sign out your current account'}</span>
        </PopupConfirm>
      </div>
    </>
  );
}

export default Header;
