import AuthForm, { STATE_LOGIN } from './authForm';
import React from 'react';
import { Col, Row } from 'reactstrap';
// import bgimg from 'assets/img/loginScreenBG.png';

import logo from '../../assets/img/logo/AmwayLogo_white.png';
import * as auth from './lib/authentication';
import './login.scss';

const helper = require('../../utils/helper');

class AuthPage extends React.Component {
  state = {
    loginProcess: false,
  };
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  componentDidMount = () => {
    auth.resetUserData();
    window.onbeforeunload = undefined;
  };

  render() {
    return (
      <div>
        <div
          className="clearcache"
          onClick={() => (window.location.href = '/clear.html')}
        >
          Clear login information
        </div>
        <Row>
          <Col md={12} lg={12} className="iss-login-header">
            <img src={logo} className="pr-2" alt="" />
          </Col>
        </Row>
        <Row
          className="iss-login-bg"
          //style={{ backgroundImage: `url(${bgimg})` }}
        >
          <Col md={12} lg={12}>
            {/* <div className="iss-login-layer"></div> */}
            <div className="iss-login-form-wrapper-right">
              <div className="iss-login-form-wrapper-center">
                <AuthForm
                  authState={this.props.authState}
                  onChangeAuthState={this.handleAuthState}
                  onLogoClick={this.handleLogoClick}
                  history={this.props.history}
                  loginProcess={this.state.loginProcess}
                  ssoUser={helper.getQSParam('user')}
                  fromLogout={helper.getQSParam('fromLogout')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} className="iss-login-footer">
            &nbsp;
          </Col>
        </Row>
      </div>
    );
  }
}

export default AuthPage;
