module.exports = global.enums = {
  passStatus : {
    NEW: 'new',
    EXPIRING: 'expiring',
    PENDING: 'pending',
    EXPIRED: 'expired',
    USED: 'used',
    INVALID: 'invalid',
  },

  actionName: {
    VIEW: 'view',
    DOWNLOAD: 'download',
    SMS: 'resendSms',
    EMAIL: 'resendEmail',
    INVALIDATE: 'invalidate',
    VALIDATE: 'validate',
  }
};
