export const SET_BREAKPOINT = "SET_BREAKPOINT";

export const setBreakpoint = (breakpoint) => ({
  type: SET_BREAKPOINT,
  payload: breakpoint,
});

const intialState = "xs";

const breakpointReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case SET_BREAKPOINT:
      return payload;
    default:
      return state;
  }
};

export default breakpointReducer;
