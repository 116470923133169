import React, { useState, useEffect } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from '../utils/bemnames';
import malaysiaFlag from '../assets/img/logo/Logo_MSIA.png';
import singaporeFlag from '../assets/img/logo/Logo_SG.png';
import bruneiFlag from '../assets/img/logo/Logo_Brunei.png';
import arrowDown from '../assets/img/icons/arrow-down.png';
import logOutIcon from '../assets/icon/LogOut.png';
import Strings from "../utils/locale";
import PopupConfirm from '../components/popupConfirm/popupConfirm';
import { logoutSession } from '../utils/login';

import { useSelector } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';

const bem = bn.create('pop-menu');
const helper = require('../utils/helper');

const CusCareMenu = () => {
  const contentStackData = useSelector(state => state?.layoutState?.layout);
  const accessToken = sessionStorage.getItem('accessToken');
  const role = helper.getTokenType(accessToken);

  const [menus] = useState([]);
  const [isAuthenticated] = useState(true);
  const [multilingualMenus, setMultilingualMenus] = useState([]);
  const [menuParentList, setMenuParentList] = useState({});
  const [curCountry, setCurCountry] = useState({ countryCode: "", countryName: "", countryFlag: null });
  const [countries] = useState([
    { countryCode: "my", countryName: Strings.COUNTRY_MALAYSIA, countryFlag: malaysiaFlag },
    { countryCode: "sg", countryName: Strings.COUNTRY_SINGAPORE, countryFlag: singaporeFlag },
    { countryCode: "bn", countryName: Strings.COUNTRY_BRUNEI, countryFlag: bruneiFlag },
  ]);
  const [supportedCountry, setSupportedCountry] = useState([]);
  const [curLanguage, setCurLanguage] = useState({ langCode: "", langName: "" });
  const [languages] = useState([
    { langCode: "zh", langName: "中文" },
    { langCode: "en", langName: "English" },
    { langCode: "ms", langName: "Bahasa Melayu" },
  ]);
  const [supportedLanguage, setSupportedLanguage] = useState([]);
  const [showLogout, setShowLogout] = useState(false);

  // from contentstack
  // const [dataCS, setDataCS] = useState({});
  // const [layoutCS, setLayoutCS] = useState({});

  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    // setDataCS(contentStackData?.data);
    // setLayoutCS(contentStackData?.layout?.mp);

    //TODO: language option
    const data = contentStackData?.menus?.multiLang;
    let sorted = data.sort(
      (a, b) => parseInt(a.sequence) - parseInt(b.sequence),
    ); // sort ascending

    console.log("Multilingual Menus 1:", multilingualMenus);
    let parentList = {};
    let supportedCountry = [];
    let supportedLanguage = [];
    sorted.map((m) => {
      if (m.title === "Country" || m.title === "Language") {
        // parent
        parentList[m.uid] = false;

        // country
        if (m.title === "Country") {
          if (!(localStorage.getItem('country') && localStorage.getItem('country') !== "")) {
            if (m.country.malaysia)
              localStorage.setItem('country', "my");
            else if (m.country.singapore)
              localStorage.setItem('country', "sg");
            else if (m.country.brunei)
              localStorage.setItem('country', "bn");
          }

          if (localStorage.getItem('country') !== "my" && m.country.malaysia)
            supportedCountry.push("my");

          if (localStorage.getItem('country') !== "sg" && m.country.singapore)
            supportedCountry.push("sg");

          if (localStorage.getItem('country') !== "bn" && m.country.brunei)
            supportedCountry.push("bn");
        }

        // language
        if (m.title === "Language") {
          if (!(localStorage.getItem('language') && localStorage.getItem('language') !== "")) {
            if (m.language.english)
              localStorage.setItem('language', "en");
            else if (m.language.chinese)
              localStorage.setItem('language', "zh");
            else if (m.language.bahasa_melayu)
              localStorage.setItem('language', "ms");
          }

          if (localStorage.getItem('language') !== "en" && m.language.english)
            supportedLanguage.push("en");

          if (localStorage.getItem('language') !== "zh" && m.language.chinese)
            supportedLanguage.push("zh");

          if (localStorage.getItem('language') !== "ms" && m.language.bahasa_melayu)
            supportedLanguage.push("ms");
        }
      }
    });

    setMultilingualMenus(sorted);
    setMenuParentList(parentList);
    setSupportedCountry(supportedCountry);

    if (role === 'abo' || role === 'apc') {
      setSupportedLanguage(supportedLanguage);
    } else if (role === 'cuscare') {
      setSupportedLanguage([]);
    } else if (role === 'hccpartner') {
      setSupportedLanguage([]);
    }

    getCurCountry();
    getCurLanguage();
  }, [contentStackData])

  const getCurCountry = () => {
    const country = localStorage.getItem('country') ?? "my";
    let countryItem = "";

    switch (country) {
      case "my":
        countryItem = countries.filter(i => i.countryCode === "my");
        break;
      case "sg":
        countryItem = countries.filter(i => i.countryCode === "sg");
        break;
      case "bn":
        countryItem = countries.filter(i => i.countryCode === "bn");
        break;
      default:
        countryItem = countries.filter(i => i.countryCode === "my");
        break;
    }
    setCurCountry(countryItem[0])
  };

  const getCurLanguage = () => {
    const language = localStorage.getItem('language') ?? "en";
    let langItem = "";

    switch (language) {
      case "zh":
        langItem = languages.filter(i => i.langCode === "zh");
        break;
      case "en":
        langItem = languages.filter(i => i.langCode === "en");
        break;
      case "ms":
        langItem = languages.filter(i => i.langCode === "ms");
        break;
      default:
        langItem = languages.filter(i => i.langCode === "en");
        break;
    }
    setCurLanguage(langItem[0]);
  };

  // const getMenusList = async () => {
  //   let csLocaleParam = (localStorage.getItem('language') || "en") + "-" + (localStorage.getItem('country') || "my");
  //   await getMenus(csLocaleParam).then(res => {
  //     //TODO: language option
  //     const data = res.data;
  //     let sorted = data.sort(
  //       (a, b) => parseInt(a.sequence) - parseInt(b.sequence),
  //     ); // sort ascending
  //     setMenus(sorted);
  //   });
  // };

  // Expanded functions
  const handleExpandClick = (e) => {
    let curUid = e.target.getAttribute("id");
    let parentList = { ...menuParentList } || {};

    if (parentList[curUid] !== undefined) {
      parentList[curUid] = !parentList[curUid];
    }

    setMenuParentList(parentList);
  };

  // Handle Country's sub menu tab
  const handleCountryChildClick = id => {
    // localStorage.setItem('country', id);
    // localStorage.setItem('language', "en");

    // console.log("Multilingual Menus 2:", multilingualMenus);
    // getMenusList();
    // getMultilingualMenusList();

    // window.location.reload(1);

    logoutSession();
    let requiredPathMY = '';
    let requiredPathBN = '';
    if (role === 'abo' || role === 'apc') {
      requiredPathMY = process.env.REACT_APP_HEALTHSCREENING_ABOAPC_URL_MY;
      requiredPathBN = process.env.REACT_APP_HEALTHSCREENING_ABOAPC_URL_BN;
    } else if (role === 'cuscare') {
      requiredPathMY = process.env.REACT_APP_HEALTHSCREENING_CUSCARE_URL_MY;
      requiredPathBN = process.env.REACT_APP_HEALTHSCREENING_CUSCARE_URL_BN;
    } else if (role === 'hccpartner') {
      requiredPathMY = process.env.REACT_APP_HEALTHSCREENING_PARTNER_URL_MY;
      requiredPathBN = process.env.REACT_APP_HEALTHSCREENING_PARTNER_URL_BN;
    }

    let currentPath = window.location.hostname;
    if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
      switch (id) {
        case "my":
          localStorage.setItem('country', id);
          localStorage.setItem('language', "en");
          window.location.href = "/";
          break;
        case "sg":
          localStorage.setItem('country', id);
          localStorage.setItem('language', "en");
          window.location.href = "/";
          break;
        case "bn":
          localStorage.setItem('country', id);
          localStorage.setItem('language', "en");
          window.location.href = "/";
          break;
        default:
          localStorage.setItem('country', "my");
          localStorage.setItem('language', "en");
          window.location.href = "/";
          break;
      }
    } else if (currentPath === 'uat.healthscreening.amway.my' || currentPath === 'healthscreening.amway.my' || currentPath === 'psup.healthscreening.amway.my' || currentPath === 'uat.cuscare.healthscreening.amway.my' || currentPath === 'cuscare.healthscreening.amway.my' || currentPath === 'psup.cuscare.healthscreening.amway.my' || currentPath === 'uat.partner.healthscreening.amway.my' || currentPath === 'partner.healthscreening.amway.my' || currentPath === 'psup.partner.healthscreening.amway.my') {
      if (top.location != self.location) {
        switch (id) {
          case "my":
            top.location = "/";
            break;
          case "bn":
            top.location = requiredPathBN;
            break;
          default:
            top.location = "/";
            break;
        }
      } else {
        switch (id) {
          case "my":
            window.location.href = "/";
            break;
          case "bn":
            window.location.href = requiredPathBN;
            break;
          default:
            window.location.href = "/";
            break;
        }
      }
    } else if (currentPath === 'uat.healthscreening.amway.com.bn' || currentPath === 'healthscreening.amway.com.bn' || currentPath === 'psup.healthscreening.amway.com.bn' || currentPath === 'uat.cuscare.healthscreening.amway.com.bn' || currentPath === 'cuscare.healthscreening.amway.com.bn' || currentPath === 'psup.cuscare.healthscreening.amway.com.bn' || currentPath === 'uat.partner.healthscreening.amway.com.bn' || currentPath === 'partner.healthscreening.amway.com.bn' || currentPath === 'psup.partner.healthscreening.amway.com.bn') {
      if (top.location != self.location) {
        switch (id) {
          case "my":
            top.location = requiredPathMY;
            break;
          case "bn":
            top.location = "/";
            break;
          default:
            top.location = "/";
            break;
        }
      } else {
        switch (id) {
          case "my":
            window.location.href = requiredPathMY;
            break;
          case "bn":
            window.location.href = "/";
            break;
          default:
            window.location.href = "/";
            break;
        }
      }
    }
  };

  // Handle Language's sub menu tab
  const handleLanguageChildClick = async (id) => {
    localStorage.setItem('language', id);

    console.log("Multilingual Menus 3:", multilingualMenus);
    //getMenusList();

    window.location.reload(1);
  };

  const handleLaunchTutorial = () => {
    localStorage.setItem('onTour', true);
    window.location.reload(1);
  }

  const handleLogoutPopup = () => {
    setShowLogout(true);
  }

  const handleActionLogout = () => {
    setShowLogout(false);
    logout();
  };

  const handleActionCancel = () => {
    setShowLogout(false);
  };

  const logout = async () => {
    const basename = 'https://amway.okta.com';
    await oktaAuth.signOut({ postLogoutRedirectUri: basename });
  };

  console.log('curCountry', curCountry);

  return (
    <>
      {!isAuthenticated ? (
        <Redirect to={helper.getLandingPage()} />
      ) : (
        <aside className={bem.b()}>
          <div className={bem.e('background')} />
          <div className={bem.e('content')}>
            <Nav vertical>
              {menus.length > 0 &&
                menus?.map((m, i) => {
                  return (
                    <NavItem
                      key={i}
                      className={`${bem.e('nav-item')}`}
                      title={m.title}
                    >
                      <BSNavLink
                        id={`navItem-${m.uid}`}
                        tag={NavLink}
                        to={m.url}
                        activeClassName="active"
                        exact={false}
                      >
                        <span className="">{m.title}</span>
                      </BSNavLink>
                    </NavItem>
                  );
                })
              }

              {multilingualMenus.length > 0 &&
                multilingualMenus.map((m, i) => {
                  let child = [];
                  if (menuParentList[m.uid] && m.title === "Country") {
                    supportedCountry?.map((cNav, cIdx) => {
                      const country = countries?.filter(i => i.countryCode === cNav);
                      child.push(
                        <div
                          className={`${"navItem"}`}
                          onClick={() => handleCountryChildClick(cNav)}
                          key={(i + 1) * 1000 + cIdx + 1}
                        >
                          <div>
                            <a>
                              <div className={"icon"}></div>
                              <div className={"countryLangText"} style={{ fontWeight: "600" }}>{country[0].countryName}</div>
                            </a>
                          </div>
                        </div>
                      );
                    });
                  } else if (menuParentList[m.uid] && m.title === "Language") {
                    supportedLanguage.map((cNav, cIdx) => {
                      const language = languages.filter(i => i.langCode === cNav);
                      child.push(
                        <div
                          className={`${"navItem"}`}
                          onClick={() => handleLanguageChildClick(cNav)}
                          key={(i + 1) * 1000 + cIdx + 1}
                        >
                          <div>
                            <a>
                              <div className={"icon"}></div>
                              <div className={"countryLangText"} style={{ fontWeight: "600" }}>{language[0].langName}</div>
                            </a>
                          </div>
                        </div>
                      );
                    });
                  }
                  return (
                    <div key={i}>
                      {m.title !== "Country" && m.title !== "Language" && m.title !== "Sign Out" && m.title !== "Tutorial" &&
                        <NavItem
                          className={`${bem.e('nav-item')}`}
                          title={m.title}
                        >
                          <BSNavLink
                            id={`navItem-${m.uid}`}
                            tag={NavLink}
                            to={m.url}
                            activeClassName="active"
                            exact={false}
                            style={{ fontWeight: "600" }}
                          >
                            <span className="">{m.title}</span>
                          </BSNavLink>
                        </NavItem>
                      }
                      {/* {m.title === "Country" &&
                        <div key={(i + 1) * 100} style={{ padding: "2px 16px" }}>
                          <div className={`${"navItem"}`} key={i}>
                            <a onClick={handleExpandClick} id={m.uid} >
                              <div className={"icon"}>
                                <img src={curCountry.countryFlag ? curCountry.countryFlag : malaysiaFlag} />
                              </div>
                              <div className={"text"} style={{ fontWeight: "600" }}>{curCountry.countryName}</div>
                            </a>
                            <div
                              className={"arrow"}
                              onClick={handleExpandClick}
                              id={m.uid}
                            >
                              <img
                                style={{ verticalAlign: "middle" }}
                                src={arrowDown}
                                className={`${"expandIcon"} ${menuParentList[m.uid] ? "edgeEnd" : ""
                                  }`}
                              />
                            </div>
                          </div>
                          {child}
                        </div>
                      } */}
                      {m.title === "Language" &&
                        <div key={(i + 1) * 100} style={{ padding: "2px 16px" }}>
                          <div className={`${"navItem"}`} key={i}>
                            <a onClick={handleExpandClick} id={m.uid}>
                              <div className={"langText"} style={{ fontWeight: "700" }}>{curLanguage.langName}</div>
                            </a>
                            {supportedLanguage.length > 0 && (
                              <div
                                className={"arrow"}
                                onClick={handleExpandClick}
                                id={m.uid}
                              >
                                <img
                                  style={{ verticalAlign: "middle" }}
                                  src={arrowDown}
                                  className={`${"expandIcon"} ${menuParentList[m.uid] ? "edgeEnd" : ""
                                    }`}
                                />
                              </div>
                            )}
                          </div>
                          {child}
                        </div>
                      }
                      {m.title === "Tutorial" && (
                        <div key={(i + 1) * 100} style={{ padding: "0px 16px" }}>
                          <div className={`${"navItem"}`} key={i}>
                            <a onClick={handleLaunchTutorial}>
                              <div className={"text"} style={{ fontWeight: "700", paddingLeft: "0" }}>{m.value}</div>
                            </a>
                          </div>
                        </div>
                      )}
                      {m.title === "Sign Out" ?
                        <>
                          <div className='dividing-line' />
                          <div key={(i + 1) * 100} style={{ padding: "0px 16px" }}>
                            <div className={`${"navItem"}`} key={i}>
                              <a onClick={handleLogoutPopup}>
                                <div className={"logout_icon"}>
                                  <img src={logOutIcon} />
                                </div>
                                <div className={"text"} style={{ fontWeight: "700" }}>{m.value}</div>
                              </a>
                            </div>
                          </div>
                        </>
                        : null
                      }


                    </div>
                  );
                })}

              <PopupConfirm
                title={multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.value || 'Sign Out'}
                showPopup={showLogout}
                okBtn={{
                  text: `${multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.confirm_button || 'Confirm'}`,
                  action: handleActionLogout,
                }}
                cancelBtn={{
                  text: `${multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.cancel_button || 'Cancel'}`,
                  action: handleActionCancel,
                }}
              >
                <span>{multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.message || 'Sign out your current account'}</span>
              </PopupConfirm>
            </Nav>
          </div>
        </aside>
      )}
    </>
  )
}

export default CusCareMenu;
