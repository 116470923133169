const { contentTypes } = require("../utils/common/contentstack-contants");
const { getContentstack } = require("../utils/common/utils");

const stack = getContentstack();

const getMultilingualSideMenusData = async (languageId) => {
  //let startTime = new Date().getTime();
  const query = stack
    .ContentType(contentTypes.multilingualMenu.typeId)
    .Query()
    .addParam("locale", languageId);

  const data = await query
    .toJSON()
    .find()
    .then((entry) => entry[0]);

  if (!data) return null;

  return data ? data : null;
};

const getFooterContentData = async (languageId) => {
  //let startTime = new Date().getTime();
  const query = stack
    .ContentType(contentTypes.footerContent.typeId)
    .Query()
    .addParam("locale", languageId);

  const data = await query
    .toJSON()
    .find()
    .then((entry) => entry[0]);

  if (!data) return null;

  return data ? data : null;
};

const getAboApcContentData = async (languageId) => {
  //let startTime = new Date().getTime();
  const query = stack
    .ContentType(contentTypes.aboApcContent.typeId)
    .Query()
    .addParam("locale", languageId);

  const data = await query
    .toJSON()
    .find()
    .then((entry) => entry[0]);

  if (!data) return null;

  return data ? data : null;
};

const getCusCareContentData = async (languageId) => {
  //let startTime = new Date().getTime();
  const query = stack
    .ContentType(contentTypes.cusCareContent.typeId)
    .Query()
    .addParam("locale", languageId);

  const data = await query
    .toJSON()
    .find()
    .then((entry) => entry[0]);

  if (!data) return null;

  return data ? data : null;
};

const getHccPartnerContentData = async (languageId) => {
  //let startTime = new Date().getTime();
  const query = stack
    .ContentType(contentTypes.hccPartnerContent.typeId)
    .Query()
    .addParam("locale", languageId);

  const data = await query
    .toJSON()
    .find()
    .then((entry) => entry[0]);

  if (!data) return null;

  return data ? data : null;
};

export const getAllLayoutData = async (currentPath, languageId) => {
  //let startTime = new Date().getTime();
  const aboApcDomain = ['uat.healthscreening.amway.my', 'psup.healthscreening.amway.my', 'healthscreening.amway.my', 'uat.healthscreening.amway.com.bn', 'psup.healthscreening.amway.com.bn', 'healthscreening.amway.com.bn'];
  const cusCareDomain = ['uat.cuscare.healthscreening.amway.my', 'psup.cuscare.healthscreening.amway.my', 'cuscare.healthscreening.amway.my', 'uat.cuscare.healthscreening.amway.com.bn', 'psup.cuscare.healthscreening.amway.com.bn', 'cuscare.healthscreening.amway.com.bn'];
  const hccPartnerDomain = ['uat.partner.healthscreening.amway.my', 'psup.partner.healthscreening.amway.my', 'partner.healthscreening.amway.my', 'uat.partner.healthscreening.amway.com.bn', 'psup.partner.healthscreening.amway.com.bn', 'partner.healthscreening.amway.com.bn'];
  let data = {};

  if (aboApcDomain.includes(currentPath)) {
    const layoutServiceList = [
      getMultilingualSideMenusData(languageId),
      getFooterContentData(languageId),
      getAboApcContentData(languageId),
    ]

    const [
      multilingualSideMenus,
      footerContent,
      aboApcContent
    ] = await Promise.allSettled(layoutServiceList);

    data = {
      menus: {
        multiLang: multilingualSideMenus?.value,
        footerContent: footerContent?.value,
      },
      layout: {
        abo: aboApcContent?.value
      },
    }
  } else if (cusCareDomain.includes(currentPath)) {
    const layoutServiceList = [
      getMultilingualSideMenusData(languageId),
      getFooterContentData(languageId),
      getCusCareContentData(languageId),
    ]

    const [
      multilingualSideMenus,
      footerContent,
      cusCareContent
    ] = await Promise.allSettled(layoutServiceList);

    data = {
      menus: {
        multiLang: multilingualSideMenus?.value,
        footerContent: footerContent?.value,
      },
      layout: {
        cuscare: cusCareContent?.value
      },
    }
  } else if (hccPartnerDomain.includes(currentPath)) {
    const layoutServiceList = [
      getMultilingualSideMenusData(languageId),
      getFooterContentData(languageId),
      getHccPartnerContentData(languageId),
    ]

    const [
      multilingualSideMenus,
      footerContent,
      hccPartnerContent
    ] = await Promise.allSettled(layoutServiceList);

    data = {
      menus: {
        multiLang: multilingualSideMenus?.value,
        footerContent: footerContent?.value,
      },
      layout: {
        partner: hccPartnerContent?.value
      },
    }
  } else if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
    const layoutServiceList = [
      getMultilingualSideMenusData(languageId),
      getFooterContentData(languageId),
      getAboApcContentData(languageId),
      getCusCareContentData(languageId),
      getHccPartnerContentData(languageId),
    ]

    const [
      multilingualSideMenus,
      footerContent,
      aboApcContent,
      cusCareContent,
      hccPartnerContent
    ] = await Promise.allSettled(layoutServiceList);

    data = {
      menus: {
        multiLang: multilingualSideMenus?.value,
        footerContent: footerContent?.value,
      },
      layout: {
        abo: aboApcContent?.value,
        cuscare: cusCareContent?.value,
        partner: hccPartnerContent?.value
      },
    }
  }

  if (!data) return null;

  return data ? data : null;
}
