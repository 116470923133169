const helper = require('../../../utils/helper');
const ux = require('../../ux-tracking-center');

export const initFiscalMonth = () => {
  // change to axios
  return fetch(helper.getAPIHost() + '/api/', helper.apiHeaders())
    .then(response => response.json())
    .then(result => {
      console.log(result);

      return true;
    });
};

export const initDashboardKPI = () => {
  try {
    // change to axios
    return fetch(helper.getAPIHost() + '/api/', helper.apiHeaders())
      .then(async response => {
        if (response.ok) {
          void response;
        }
      })
      .catch(e => {
        ux.logError('DataInit', 'initDashboardKPI catch 1', JSON.stringify(e));

        return true;
      });
  } catch (err) {
    ux.logError('DataInit', '', JSON.stringify(err));

    return true;
  }
};
