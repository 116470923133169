import React from "react";
import PropTypes from "prop-types";
import * as iconData from "./svg-data";
import "./icon.scss";
import uiStyles from "../../styles/styles.module.scss";

/** Constants **/
const VIEWBOX_DEFAULT = 40;

/** Icon Component **/
const Icon = (props) => {
  const { id } = props;
  const colorClass = uiStyles[`text_${props.color}`];
  //const size = `${props.size}px`;
  const sizeStyle = {
    // height: size,
    // width: size,
  };

  const getSvg = (name) => {
    const icon = iconData.iconsByName(name);
    if (icon !== undefined) {
      const viewHeight = Object.prototype.hasOwnProperty.call(icon, "viewHeight")
        ? icon.viewHeight
        : VIEWBOX_DEFAULT;
      const viewWidth = Object.prototype.hasOwnProperty.call(icon, "viewWidth")
        ? icon.viewWidth
        : VIEWBOX_DEFAULT;
      const svgStyle = Object.prototype.hasOwnProperty.call(icon, "style") ? icon.style : null;
      const embedColor = Object.prototype.hasOwnProperty.call(icon, "pathColors");

      return (
        <svg
          width={viewWidth}
          height={viewHeight}
          viewBox={`0 0 ${viewWidth} ${viewHeight}`}
          version="1.1"
          aria-hidden
          fill="currentColor"
          focusable={false}
          xmlns="http://www.w3.org/2000/svg"
          style={svgStyle}
        >
          <g stroke="none" strokeWidth="1" fillRule="evenodd">
            {icon.paths.map((path, index) => (
              <path
                key={index}
                d={path}
                fill={embedColor ? icon.pathColors[index] : "currentColor"}
              />
            ))}
          </g>
        </svg>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            fontSize: "200%",
          }}
        >
          ?
        </div>
      );
    }
  };

  return (
    <div {...(id && { id: id })} className="icon">
      <div style={sizeStyle} className={`${colorClass} ${"svg_wrapper"}`}>
        {getSvg(props.name)}
      </div>
    </div>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(iconData.icons.map(x => x.name)),
  size: PropTypes.number,
  color: PropTypes.oneOf(Object.keys(uiStyles).filter(x => x.includes('text_')).map(x => x.replace('text_', ''))),
  id: PropTypes.string,
};

Icon.defaultProps = {
  name: "",
  size: 24,
  color: "dark_gray",
  id: "",
};

export default Icon;
