import React from "react";
import "./newSpinner.scss";

export default function NewSpinner({ size, borderColor, borderTopColor, title }) {
  let loaderStyle;
  switch (size) {
    case "sm":
      loaderStyle = {
        height: "20px",
        width: "20px",
        borderColor: borderColor,
        borderTopColor: borderTopColor,
      };
      break;
    case "md":
      loaderStyle = {
        height: "40px",
        width: "40px",
        borderColor: borderColor,
        borderTopColor: borderTopColor,
      };
  }

  return (
    <div className={"new_loader_wrapper"}>
      <div className={"loader"} style={loaderStyle}></div>
      {title && (<div className={"loader_title"}>{title}</div>)}
    </div>
  );
}
