import React, { useState } from 'react';
import './login.scss';
import { useEffect } from 'react';
import PageLoader from '../../../components/PageLoader';
import Breakpoint from "../../../utils/breakpoint";
import logo from '../../../assets/img/logo/AmwayLogo_black.png';
import { Form, FormGroup } from 'reactstrap';
import { isEmpty } from 'lodash';
import { MdClose } from 'react-icons/md';
import Icon from "../../../components/icon/icon";
import NewSpinner from "../../../components/newSpinner/newSpinner";
import Cookies from 'universal-cookie';
import * as ls from '../../../utils/loginSession';
import { getPartnerLogin } from '../../../controllers/loginController';
import base64 from 'base-64';

function Login() {
  const [pageLoading, setPageLoading] = useState(true);
  const [breakpoint, setBreakpoint] = useState("xs");
  let isDesktop = breakpoint !== 'xs' && breakpoint !== 'sm';

  const [errorMessage, setErrorMessage] = useState('');
  const [loginProcess, setLoginProcess] = useState(false);

  const [loginUserName, setLoginUserName] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [showErrorInfoPopup, setShowErrorInfoPopup] = useState(true);

  useEffect(() => {
    setPageLoading(false);
    localStorage.setItem('role', "hccpartner");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginProcess(true);
    setShowErrorInfoPopup(true);
    setErrorMessage('');
    
    if (isEmpty(loginUserName) || isEmpty(loginPassword)) {
      if (isEmpty(loginUserName))
        setIsValidUsername(false);

      if (isEmpty(loginPassword))
        setIsValidPassword(false);
    } else {
      let data = await getPartnerLogin({ userName: loginUserName, password: loginPassword });
      if (data?.token?.access_token && data?.token?.access_token !== '') {
        let accessToken = "";

        // Ddue to SDK required window & localStorage object
        if (typeof window !== 'undefined') {
          const cookies = new Cookies();
          accessToken = data?.token?.access_token;

          let exp = new Date().getTime() + ls.getLoginMaxIdle() * 60 * 1000;
          cookies.set('accessToken', accessToken || '', ls.cookieOption(exp));

          sessionStorage.setItem('accessToken', accessToken);
          localStorage.setItem('userId', data?.userId);
          localStorage.setItem('outletId', data?.outletId);
          localStorage.setItem('permission', base64.encode(data?.permission.trim().toLowerCase()));

          window.location.href = '/home';
        }
      } else {
        setErrorMessage(data);
      }
    }
    setLoginProcess(false);
  };

  const handleUsernameChange = event => {
    event.preventDefault();
    if (!isEmpty(event))
      setIsValidUsername(true);
    setLoginUserName(event.target.value);

  };

  const handlePasswordChange = event => {
    event.preventDefault();
    if (!isEmpty(event))
      setIsValidPassword(true);
    setLoginPassword(event.target.value);

  };

  const handleCloseErrorInfoPopup = () => {
    setShowErrorInfoPopup(false);
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  }

  return (
    <>
      <Breakpoint onBreakpointChange={setBreakpoint} />
      <PageLoader PageLoaderState={pageLoading}>
        <div className="loginSite">
          <div className="login-form" style={{ width: isDesktop ? '450px' : '100%' }}>
            <div className="logo_wrapper">
              <img src={logo} alt="Amway Logo" />
            </div>
            <div className="title_wrapper">
              <span>Sign in</span>
            </div>

            <div className="input_wrapper">
              <Form onSubmit={handleSubmit}>
                {showErrorInfoPopup && errorMessage !== '' && (
                  <div className="error_wrapper">
                    <div className="error-box">
                      <div className="error-top">
                        {"INFO"}:
                        <button onClick={() => handleCloseErrorInfoPopup()}><MdClose size={14} /></button>
                      </div>
                      <div className="error-content">
                        {"Your User Name and Password combination does not match our records, please try again."}
                      </div>
                    </div>
                  </div>
                )}

                <FormGroup>
                  <div className="login-field-wrapper">
                    <span
                      style={{
                        color: !isValidUsername ? '#a94442' : ''
                      }}>
                      User Name
                    </span>
                    <input type="text" maxLength={50} onChange={handleUsernameChange} value={loginUserName}
                      placeholder={"Username"}
                      style={{
                        borderColor: !isValidUsername ? '#a94442' : '',
                        borderWidth: !isValidUsername ? '2px' : '',
                        outline: !isValidUsername ? 'none' : ''
                      }}>
                    </input>
                    {!isValidUsername && (
                      <div className="error-info">
                        A valid User Name is required.
                      </div>
                    )}
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="login-field-wrapper">
                    <span
                      style={{
                        color: !isValidPassword ? '#a94442' : ''
                      }}>
                      Password
                    </span>
                    <input type={isShowPassword ? "text" : "password"} maxLength={50} onChange={handlePasswordChange} value={loginPassword}
                      placeholder={"Password"}
                      style={{
                        borderColor: !isValidPassword ? '#a94442' : '',
                        borderWidth: !isValidPassword ? '2px' : '',
                        outline: !isValidPassword ? 'none' : ''
                      }}>
                    </input>
                    <div className="eye" onClick={handleShowPassword}>
                      {isShowPassword && <Icon name={"open_eye"} />}
                      {!isShowPassword && <Icon name={"cross_eye"} />}
                    </div>
                    {!isValidPassword && (
                      <div className="error-info">
                        A valid Password is required.
                      </div>
                    )}
                  </div>
                </FormGroup>

                <button size="lg" className="iss-login-button" onClick={handleSubmit}>
                  {loginProcess ? <NewSpinner title={'SIGNING IN...'} /> : 'Sign In'}
                </button>
                {/* <div className="forgotPWD_wrapper">
                  <span>Forgot Password</span>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </PageLoader>
    </>
  );
}

export default Login;
