/**
 *
 * ms-my
 *
 * Malaysia's Bahasa Melayu locale labels
 */

export const Strings = {
  COUNTRY_MALAYSIA: "Malaysia",
  COUNTRY_SINGAPORE: "Singapore",
  COUNTRY_BRUNEI: "Brunei",

  DASHBOARD_MY_PERFORMANCE: "Pencapaian Saya",
};
