const { default: axios } = require("axios");
import { formatDate } from '../utils/helper';

export const getPassReportData = async (params) => {
  var paramMonth;
  switch (params.paramMth) {
    case "Jan":
      paramMonth = '01';
      break;
    case "Feb":
      paramMonth = '02';
      break;
    case "Mar":
      paramMonth = '03';
      break;
    case "Apr":
      paramMonth = '04';
      break;
    case "May":
      paramMonth = '05';
      break;
    case "June":
      paramMonth = '06';
      break;
    case "Jul":
      paramMonth = '07';
      break;
    case "Aug":
      paramMonth = '08';
      break;
    case "Sep":
      paramMonth = '09';
      break;
    case "Oct":
      paramMonth = '10';
      break;
    case "Nov":
      paramMonth = '11';
      break;
    case "Dec":
      paramMonth = '12';
      break;
    case "All":
      paramMonth = 'All';
      break;
    default:
      paramMonth = '01';
      break;
  }

  var data;
  var url = process.env.REACT_APP_API_URL + `/healthscreening/partner/generatereport/?month=${paramMonth}&year=${params.paramYr}&outlet=${params.paramLoc}`
  console.log("url", url);

  const res = await axios(url, {
    headers: {
      'Content-type': 'application/json'
    },
    method: 'POST',
  });

  data = res.data.data;
  //console.log("Pass Report Data: ", data);

  var tmpData = data;
  tmpData.forEach(passReport => {
    var valRedeemedDate = formatDate(passReport.dt_redeemed);
    let dtRedeemedDate = new Date(valRedeemedDate);
    let reportMth = dtRedeemedDate.getMonth() + 1;
    let reportYr = dtRedeemedDate.getFullYear();

    passReport.month = reportMth;
    passReport.year = reportYr;
  });

  const groupBy = (data, keys) => {
    return Object.values(
      data.reduce((acc, val) => {
        const name = keys.reduce((finalName, key) => finalName + val[key] + '/', '').slice(0, -1);

        if (acc[name]) {
          acc[name].passReportList.push(val);
          //acc[name].sum += val.amount;
        } else {
          acc[name] = {
            name,
            //sum:val.amount,
            month: val.month,
            year: val.year,
            passReportList: [val],
            /*
            passReportList: [{
              pass_no: val.pass_no,
              dt_create: val.dt_create,
              redeemed_by_outlet_id: val.redeemed_by_outlet_id,
              abo_name: val.abo_name,
              mobile: val.mobile,
              email_address: val.email_address,
            }]
            */
          };
        }
        return acc;
      }, {})
    );
  };

  /*
  const groupByData = tmpData.groupBy(tempData => {
    return tempData.year, tempData.month;
  });
  */

  // console.log(JSON.stringify(groupBy(tmpData, ['year','month']), null, 2));
  // console.log("groupedData", JSON.stringify(groupByData, null, 2));

  return groupBy(tmpData, ['year', 'month']);

};

export const getOutletsData = async () => {
  try {
    var data;
    var url = process.env.REACT_APP_API_URL + "/healthscreening/partner/getoutlets";

    const res = await axios(url, {
      headers: {
        'Content-type': 'application/json'
      },
      method: 'GET',
      params: {},
    });

    if (res.status !== 200 || res?.data?.status !== 200)
      throw 'Request Error to getOutletsData';

    data = res.data;

    return data;
  } catch (error) {
    return error;
  }
};
