export const disableEnableScroll = (scrollOff) => {
  if (typeof document !== "undefined" && document.body) {
    if (scrollOff && document.documentElement && window && window.innerWidth) {
      const scrollBarGap =
        window.innerWidth - document.documentElement.clientWidth;
      if (scrollBarGap > 0) {
        document.body.style.paddingRight = `${scrollBarGap}px`;
      }
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.paddingRight = "inherit";
    }
  }
};

export const logError = (msg, e) => {
  if (window !== undefined) {
    /* eslint-disable no-console */
    console.log(msg, e);
  }
};
