/**
 *
 * Main locale file for this BodyKey Health Screening Pass. Determines which locale to be used on render.
 *
 * Keys across all locale files must be in sync
 */

import { Strings as zh_my } from "./zh-my";
import { Strings as en_my } from "./en-my";
import { Strings as ms_my } from "./ms-my";
import { Strings as zh_sg } from "./zh-sg";
import { Strings as en_sg } from "./en-sg";
import { Strings as zh_bn } from "./zh-bn";
import { Strings as en_bn } from "./en-bn";
import { Strings as ms_bn } from "./ms-bn";

let currentLocale = "en-my";

function getLocaleStrings() {
  const getCurrentLocale = () => {
    currentLocale = (localStorage.getItem('language') || "en") + "-" + (localStorage.getItem('country') || "my");

    switch (currentLocale) {
      case "zh-my":
        return zh_my;
      case "en-my":
        return en_my;
      case "ms-my":
        return ms_my;
      case "zh-sg":
        return zh_sg;
      case "en-sg":
        return en_sg;
      case "zh-bn":
        return zh_bn;
      case "en-bn":
        return en_bn;
      case "ms-bn":
        return ms_bn;
      default:
        return en_my;
    }
  };

  return getCurrentLocale();
}

export default getLocaleStrings();
