const { getAllLayoutData } = require("../services/layoutService");

export const getAllLayout = async (currentPath, languageId) => {
  try {
    const data = await getAllLayoutData(currentPath, languageId);

    return data;
  } catch (err) {
    console.log(`Error in getAllLayout: ${err}`);
  }
}
