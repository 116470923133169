import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import './login.scss';
import { RiShieldUserLine } from 'react-icons/ri';
import * as auth from './lib/authentication';
import PageSpinner from '../../components/PageSpinner';
import { MdPerson, MdPassword } from 'react-icons/md';

const initData = require('./lib/dataInitialization');

class AuthForm extends React.Component {
  state = {
    username: '',
    password: '',
    errorMessage: '',
    loginProcess: false,
    ssoLoginProcess: false,
    ssoChecked: false,
  };

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (
      !this.state.username &&
      (!this.state.password ||
        this.state.username === 'storemanager' ||
        this.state.username === 'associate')
    ) {
      this.setState({ errorMessage: 'Please fill in Username and Password!' });
    } else if (!this.state.username) {
      this.setState({ errorMessage: 'Please fill in Username!' });
    } else if (!this.state.password) {
      this.setState({ errorMessage: 'Please fill in Password!' });
    }
    if (
      this.state.username &&
      (this.state.password ||
        this.state.username === 'storemanager' ||
        this.state.username === 'associate')
    ) {
      this.setState({ loginProcess: true, errorMessage: '' });
      auth.doLogin(this.state.username, this.state.password).then(result => {
        if (result.status) {
          console.log('Login success');
          Promise.all([
            initData.initFiscalMonth(),
            initData.initDashboardKPI(),
            // if more data need to init, append here
          ]).then(([ret1, ret2]) => {
            void ret1;
            void ret2;
            window.location.href = '/home';
          });
        } else {
          console.log('Login failed');
          this.setState({
            errorMessage: result.message,
            loginProcess: false,
          });
        }
      });
    }
  };

  backToLanding = () => {
    window.location = '/landing';
  };
  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Sign In';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      usernameInputProps,
      passwordInputProps,
      children,
      //ssoUser,
      //fromLogout,
    } = this.props;
    // For LOCAL Environment

    if (this.props.authState === 'LOGOUT') auth.doLogout();
    else if (auth.isAuthenticated()) window.location.href = '/home';
    else {
      auth.resetUserData();
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.state.loginProcess && <PageSpinner />}
        <div className="iss-login-title">
          {localStorage.getItem('SSOUser') !== '' &&
            localStorage.getItem('SSOUser') !== null &&
            localStorage.getItem('SSOSuccess') !== undefined &&
            localStorage.getItem('SSOSuccess') !== null &&
            localStorage.getItem('SSOSuccess') === '1' && (
              <div
                className="ssoLoginButton"
                title={`Login as ${localStorage.getItem('SSOUser')}`}
                onClick={this.backToLanding}
              >
                <RiShieldUserLine />
                <span>SSO</span>
              </div>
            )}
        </div>
        {this.state.errorMessage && (
          <div className="iss-login-error">
            <span>Error: </span>
            {this.state.errorMessage}
          </div>
        )}
        <FormGroup>
          <div className="iss-login-field-wrapper">
            <MdPerson />
            <Input
              {...usernameInputProps}
              onChange={this.handleUsernameChange}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="iss-login-field-wrapper">
            <MdPassword />
            <Input
              {...passwordInputProps}
              onChange={this.handlePasswordChange}
            />
          </div>
        </FormGroup>

        <button
          size="lg"
          className="iss-login-button"
          onClick={this.handleSubmit}
        >
          {this.renderButtonText()}
        </button>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_LOGOUT = 'LOGOUT';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_LOGOUT, STATE_SIGNUP])
    .isRequired,
  usernameInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  ssoUser: PropTypes.string,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',

  usernameInputProps: {
    id: 'username',
    type: 'text',
    placeholder: 'Username',
    required: 'required',
  },
  passwordInputProps: {
    id: 'password',
    type: 'password',
    placeholder: 'Password',
    required: 'required',
  },
  ssoUser: '',
  fromLogout: '',
};

export default AuthForm;
