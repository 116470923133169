import React, { useState } from 'react';
import { useSelector } from "react-redux";
import bn from '../utils/bemnames';
import Breakpoint from "../utils/breakpoint";
const bem = bn.create('footer');

const Footer = () => {
  const footerContentCS = useSelector(state => state?.layoutState?.layout?.menus?.footerContent[0]);
  const [breakpoint, setBreakpoint] = useState("xs");
  let isDesktop = breakpoint !== 'xs' && breakpoint !== 'sm';

  const handleClickLink = link => {
    const urlExtension = link.split(/[#?]/)[0].split('.').pop().trim();
    if (urlExtension === 'pdf') {
      window.open(link);
    } else {
      if (top.location != self.location)
        top.location = link;
      else
        window.location.href = link;
    }
  };

  return (
    <>
      <Breakpoint onBreakpointChange={setBreakpoint} />

      {isDesktop ? (
        <footer className={`${bem.b()}-${isDesktop ? 'desktop' : ''}`}>
          <div className='left_div'>
            <div className='address'>
              <p>
                {footerContentCS?.details?.contact
                  ?.split("<br/>")?.map((str, idx) => (
                    <span key={idx}>
                      {idx > 0 && idx != 3 && <br />}
                      {idx == 2 && <br />}
                      {idx == 3 && '\xa0'.repeat(2)}
                      {str}
                    </span>
                  ))
                }
              </p>
            </div>
          </div>
          <div className='right_div'>
            <div className='media'>
              <div className='mediaWrapper'>
                <span>{footerContentCS?.connect_with_amway}</span>
                <div className='logo'>
                  {footerContentCS?.connect_media?.map(m => (
                    <div
                      key={m._metadata?.uid}
                      onClick={e => {
                        e.stopPropagation();
                        handleClickLink(m.link?.href);
                      }}
                    >
                      <img src={m.icon?.url} title={m.link?.title} alt={m.alt} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='copyright'>
              <p style={{ margin: 'auto', width: '100%' }}>
                {footerContentCS?.details?.terms?.map((t, idx) => (
                  <span key={t._metadata?.uid}
                    onClick={e => {
                      e.stopPropagation();
                      handleClickLink(t.link?.href);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {idx > 0 && ' | '}
                    {t.link?.title}
                  </span>
                ))}
              </p>
              {footerContentCS?.details?.credit?.map(c => (
                <img key={c._metadata?.uid} src={c.img?.url} title={c.link?.title} alt={c.alt}
                  onClick={e => {
                    e.stopPropagation();
                    handleClickLink(c.link?.href);
                  }}
                  style={{ maxWidth: '75px' }}
                />
              ))}
            </div>
          </div>
        </footer>
      ) : (
        <footer className={`${bem.b()} ${isDesktop ? 'desktop' : ''}`}>
          <div className='media'>
            <span>{footerContentCS?.connect_with_amway}</span>
            <div className='logo'>
              {/* <a href='https://www.facebook.com/amwaymalaysia' ><img src={facebookIcon} title='facebook.com' alt='facebook' /></a>
          <a href='https://www.instagram.com/amwaymy' ><img src={instagramIcon} title='instagram.com' alt='instagram' /></a>
          <a href='http://www.youtube.com/amwaymalaysia'><img src={youtubeIcon} title='youtube.com' alt='youtube' /></a> */}
              {footerContentCS?.connect_media?.map(m => (
                <div
                  key={m._metadata?.uid}
                  onClick={e => {
                    e.stopPropagation();
                    handleClickLink(m.link?.href);
                  }}
                >
                  <img src={m.icon?.url} title={m.link?.title} alt={m.alt} />
                </div>
              ))}
            </div>
          </div>
          <div className='copyright'>
            <p>
              {footerContentCS?.details?.copyright
                ?.replace('{copyright-year}', new Date().getFullYear())
                ?.split("<br/>")?.map((str, idx) => (
                  <span key={idx}>
                    {idx > 0 && <br />}
                    {str}
                  </span>
                ))
              }
            </p>
            <p style={{ margin: 'auto', width: '290px' }}>
              {footerContentCS?.details?.terms?.map((t, idx) => (
                <span key={t._metadata?.uid}
                  onClick={e => {
                    e.stopPropagation();
                    handleClickLink(t.link?.href);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {idx > 0 && ' | '}
                  {t.link?.title}
                </span>
              ))}
            </p>
            <p>
              {footerContentCS?.details?.contact
                ?.split("<br/>")?.map((str, idx) => (
                  <span key={idx}>
                    {idx > 0 && <br />}
                    {str}
                  </span>
                ))
              }
            </p>
            {footerContentCS?.details?.credit?.map(c => (
              <img key={c._metadata?.uid} src={c.img?.url} title={c.link?.title} alt={c.alt}
                onClick={e => {
                  e.stopPropagation();
                  handleClickLink(c.link?.href);
                }}
                style={{ maxWidth: '75px' }}
              />
            ))}
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
