const contentstack = require("contentstack");

export const getContentstack = () => {
  const stack = contentstack.Stack(
    process.env.REACT_APP_CS_API_KEY,
    process.env.REACT_APP_CS_DELIVERY_TOKEN,
    process.env.REACT_APP_LOGIN_ENV === "prod" ? 
      "prod" 
      : process.env.REACT_APP_LOGIN_ENV === "psup" ?
        "psup"
        : "uat"
  );

  return stack;
};
