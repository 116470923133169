import Cookies from 'universal-cookie';
//import * as ls from './loginSession';
import jwt from "jsonwebtoken";
import { getTokenType } from '../utils/helper';

//========================================================================//

export const loginSession = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    sessionStorage.setItem('accessToken', cookies.get('accessToken'));

    const accessToken = sessionStorage.getItem('accessToken');

    const role = getTokenType(accessToken);
    if (role === 'abo' || role === 'apc') {
      localStorage.setItem('aff', jwt?.decode(accessToken)?.account?.sales_plan_aff);
      localStorage.setItem('abo', jwt?.decode(accessToken)?.account?.abo);
      localStorage.setItem('tokenCountry', jwt?.decode(accessToken)?.account?.country.toLowerCase());
      localStorage.setItem('aboName', jwt?.decode(accessToken)?.account?.abo_name);
      localStorage.setItem('accSubType', jwt?.decode(accessToken)?.account?.acct_subtype);
      localStorage.setItem('accType', jwt?.decode(accessToken)?.account?.acct_type);
      localStorage.setItem('renewalFlag', jwt?.decode(accessToken)?.account?.renewal_flag);
      localStorage.setItem('level', jwt?.decode(accessToken)?.account?.level);

      localStorage.setItem('globalPartyID', jwt?.decode(accessToken)?.gbl_partyid);
    } else if (role === 'cuscare') {
      //Added Additional login details for Amway Customer Care
      console.log('Amway Customer Care');
    } else if (role === 'hccpartner') {
      //Added Additional login details for Health Care Center Partner
      console.log('Health Care Center Partner');
    }

  } else {
    console.log('Login session valid on browser session only');
  }
};

export const logoutSession = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    console.log('Remove cookies');
    // reset general login data
    cookies.remove('accessToken');
    cookies.remove('idToken');

    const accessToken = sessionStorage.getItem('accessToken');
    const role = getTokenType(accessToken);
    
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('idToken');
    localStorage.removeItem('loginFlag');
    localStorage.removeItem('role');

    if (role === 'abo' || role === 'apc') {
      localStorage.removeItem('aff');
      localStorage.removeItem('abo');
      localStorage.removeItem('tokenCountry');
      localStorage.removeItem('aboName');
      localStorage.removeItem('accSubType');
      localStorage.removeItem('accType');
      localStorage.removeItem('renewalFlag');
      localStorage.removeItem('level');

      localStorage.removeItem('globalPartyID');
    } else if (role === 'cuscare') {
      //Removed login details for Amway Customer Care
      console.log('Amway Customer Care');
    } else if (role === 'hccpartner') {
      //Removed login details for Health Care Center Partner
      console.log('Health Care Center Partner');

      localStorage.removeItem('userId');
      localStorage.removeItem('outletId');
      localStorage.removeItem('permission');
    }
  } else {
    console.log('Logout session valid on browser session only');
  }
};
