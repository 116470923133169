/**
 *
 * en-bn
 *
 * Brunei's English locale labels
 */

export const Strings = {
  COUNTRY_MALAYSIA: "Malaysia",
  COUNTRY_SINGAPORE: "Singapore",
  COUNTRY_BRUNEI: "Brunei",

  DASHBOARD_MY_PERFORMANCE: "My Performance",
};
