const { default: axios } = require("axios");

export const getPartnerLoginData = async (params) => {
  try {
    var data;
    var url = process.env.REACT_APP_API_URL + "/healthscreening/" + "partner" + "/login";

    let paramBody = { "search": {} };
    if (params.userName && params.password) {
      paramBody = { ...paramBody, search: { ...paramBody.search, uid: params.userName, pwd: params.password } }
    }

    const res = await axios(url, {
      method: 'POST',
      data: paramBody,
      headers: {
        'Content-type': 'application/json'
      },
    });

    if (res.status !== 200 || res?.data?.status !== 200)
      throw 'Request Error to getPartnerLoginData';

    data = res.data;

    return data;
  } catch (error) {
    return error;
  }
};
