import { Content, Footer, Header, CusCareHeader } from './index';
import React from 'react';
import { withRouter } from 'react-router-dom';
// notification
import { MdInfo } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import '../styles/fonts/font.scss';

class MainLayout extends React.Component {
  UNSAFE_componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  notification(message, type, children, delay) {
    // https://snyk.io/advisor/npm-package/react-notification-system

    if (!delay) delay = 0;

    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      if (!type) type = 'success';
      if (!children) children = '';

      this.notificationSystem.addNotification({
        title: <MdInfo />,
        message: message,
        level: type,
        children: children,
      });
    }, delay);
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);

    window.message.notification = this.notification.bind(this);
    window.redirect = this.redirect.bind(this);

    /*
    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdInfo />,
        message: 'Welome to Reduction Admin!',
        level: 'info',
      });
    }, 1500);
*/
    /*setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message:
          'Reduction is carefully designed template powered by React and Bootstrap4!',
        level: 'info',
      });
    }, 2500);*/
  }

  // close sidebar when
  /*handleContentClick = () => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };*/

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('close');
    }
  }

  openSidebar(openOrClose) {
    void openOrClose;
    /*
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        ?.classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar')?.classList.remove('cr-sidebar--open');
    */
  }

  redirect(url) {
    this.props.history.push(url);
  }

  render() {
    const { children } = this.props;
    //onClick={this.handleContentClick}
    const currentPath = window.location.hostname;
    let accType = '';
    if (currentPath === 'localhost' || currentPath === '172.0.0.1')
      accType = 'isLocalhost';
    else if (currentPath === 'uat.healthscreening.amway.my' || currentPath === 'psup.healthscreening.amway.my' || currentPath === 'healthscreening.amway.my' || currentPath === 'uat.healthscreening.amway.com.bn' || currentPath === 'psup.healthscreening.amway.com.bn' || currentPath === 'healthscreening.amway.com.bn')
      accType = 'isAboApc';
    else if (currentPath === 'uat.cuscare.healthscreening.amway.my' || currentPath === 'psup.cuscare.healthscreening.amway.my' || currentPath === 'cuscare.healthscreening.amway.my' || currentPath === 'uat.cuscare.healthscreening.amway.com.bn' || currentPath === 'psup.cuscare.healthscreening.amway.com.bn' || currentPath === 'cuscare.healthscreening.amway.com.bn')
      accType = 'isCusCare';
    else if (currentPath === 'uat.partner.healthscreening.amway.my' || currentPath === 'psup.partner.healthscreening.amway.my' || currentPath === 'partner.healthscreening.amway.my' || currentPath === 'uat.partner.healthscreening.amway.com.bn' || currentPath === 'psup.partner.healthscreening.amway.com.bn' || currentPath === 'partner.healthscreening.amway.com.bn')
      accType = 'isHccPartner';

    return (
      <main className="cr-app iss-page-bg">
        <Content fluid>
          {(accType === 'isLocalhost' || accType === 'isAboApc' || accType === 'isHccPartner') && <Header />}
          {accType === 'isCusCare' && <CusCareHeader />}
          <div style={{minHeight: 'calc(100vh - 425px'}}>{children}</div>
          <Footer />
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
    );
  }
}

export default withRouter(MainLayout);
