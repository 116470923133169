import { getPassReport, getOutlets } from '../../../../controllers/PassReportController';

export const enums = {
  SET_PASS_REPORT_STATE: { type: 'SET_PASS_REPORT_STATE', state: 'passReport' },
  SET_PASS_OUTLET_STATE: { type: 'SET_PASS_OUTLET_STATE', state: 'passOutlet' }
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchPassReportState = params => async (dispatch) => {
  try {
    let data = await getPassReport(params);
    if (data) {
      dispatch(setState(enums.SET_PASS_REPORT_STATE, { ...data }));
      return { ...data };
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchOutletsState = () => async (dispatch) => {
  try {
    let data = await getOutlets();
    if (data) {
      dispatch(setState(enums.SET_PASS_OUTLET_STATE, { ...data }));
      return data;
    }
  } catch (err) {
    console.log(err);
  }
}

// state
const passReportState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  passReportState,
};
