import React from 'react';
import { Drawer } from '@mui/material';
import styles from "./Drawer.scss";

const DrawerComponent = (props) => {  
  const isIOS = () => {
    if (typeof window !== "undefined") {
      if (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return <Drawer className="Drawer__container" {...props} classes={{ paper: isIOS() ? styles.paper_container_iOS : styles.paper_container }}></Drawer>
}


export default DrawerComponent;
