import React from "react";
import PropTypes from "prop-types";

/** Constants **/
const breakpoints = {
  xs: 600,
  sm: 767,
  md: 1200,
  lg: 1440,
};

export default class Breakpoint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currWidth: null,
      breakpoint: props.defaultBreakpoint,
    };
    this.resizeHandler = this.resizeHandler.bind(this);
    this.getBreakpoint = this.getBreakpoint.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      const breakpoint = this.getBreakpoint(width);
      window.addEventListener("resize", this.resizeHandler, false);
      if (breakpoint !== this.state.breakpoint) {
        this.props.onBreakpointChange(breakpoint);
      }
      this.setState({
        currWidth: width,
        breakpoint: breakpoint,
      });
    }
  }

  UNSAFE_componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.resizeHandler, false);
    }
  }

  resizeHandler() {
    if (typeof window !== "undefined") {
      const currWidth = window.innerWidth;
      const breakpoint = this.getBreakpoint(currWidth);
      if (
        this.state.breakpoint !== breakpoint &&
        this.props.onBreakpointChange
      ) {
        this.setState({
          breakpoint: breakpoint,
        });
        this.props.onBreakpointChange(breakpoint);
      }
    }
  }

  getBreakpoint(width) {
    return width <= breakpoints.xs
      ? "xs"
      : width <= breakpoints.sm
      ? "sm"
      : width <= breakpoints.md
      ? "md"
      : "lg";
  }

  render() {
    return null;
  }
}

/** Props **/

Breakpoint.defaultProps = {
  onBreakpointChange: () => {},
  defaultBreakpoint: "xs",
};

Breakpoint.propTypes = {
  onBreakpointChange: PropTypes.func, //send breakpoint as parameter to callback
  defaultBreakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};
