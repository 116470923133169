const { getPassReportData } = require("../services/passReportService");
const { getOutletsData } = require("../services/passReportService");

export const getPassReport = async (params) => {
  try {
    console.log("params", params);
    let data = await getPassReportData(params);

    if (!data)
      throw "Error in getPassReport";
    
    return {
      data
    };
  } catch (err) {
    console.log(`Error in getPassReport: ${err}`);
  }
};

export const getOutlets = async () => {
  try {
    let data = await getOutletsData();

    if (data && data.outlets && data.outlets.length > 0) {
      data = data?.outlets;
    } else if (!data) {
      throw "Error in getOutlets";
    }

    return data;
  } catch (err) {
    console.log(`Error in getOutlets: ${err}`);
  }
};
