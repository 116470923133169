/**
 *
 * zh-sg
 *
 * Singapore's Mandarin locale labels
 */

export const Strings = {
  COUNTRY_MALAYSIA: "马来西亚",
  COUNTRY_SINGAPORE: "新加坡",
  COUNTRY_BRUNEI: "汶萊",

  DASHBOARD_MY_PERFORMANCE: "我的表现",
};
