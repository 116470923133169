import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

root.render(
  <Provider store={store}>
    <BrowserRouter basename={getBasename()}>
      <App />
    </BrowserRouter>
  </Provider>,
);
