import { getTokenType } from '../../utils/helper';
const { getAllLayout } = require("../../controllers/layoutController");

export const enums = {
  SET_LAYOUT: {
    type: 'SET_LAYOUT',
    state: 'layout'
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchLayout = () => async (dispatch) => {
  //let currentLocale = (localStorage.getItem('language') || "en") + "-" + (localStorage.getItem('country') || "my");
  let currentLocale = (localStorage.getItem('language') || "en") + "-" + ("my");
  try {
    const languageId = currentLocale || "en-my";
    let currentPath = window.location.hostname;

    let res = await getAllLayout(currentPath, languageId);
    console.log("fetchLayout res ", res);

    const accessToken = sessionStorage.getItem('accessToken');
    let role = getTokenType(accessToken);

    switch (role) {
      case "cuscare":
        res = { ...res, layout: res.layout.cuscare[0] }
        break;
      case "hccpartner":
        res = { ...res, layout: res.layout.partner[0] }
        break;
      default:
        res = { ...res, layout: res.layout.abo[0] }
        break;
    }

    dispatch(setState(enums.SET_LAYOUT, { ...res }));
    return { ...res };
  } catch (err) {
    console.log(err);
  }
};

// state
const layoutState = (state = {}, action) => {
  switch (action.type) {
    // for special case

    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

      // for default
      return state;
  }
};

export default {
  layoutState,
};
