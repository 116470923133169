import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

// Page Info
import breakpointReducer from "./utils/breakpoint-reducer";

import layoutReducer from "./redux/reducers/index";
import passListReducer from "./redux/reducers/passListReducer";
import passDetailsReducer from "./modules/BGHViewPass/redux/reducers/index";
import passReportReducer from "./modules/passReport/redux/reducers/index";
import passReducer from "./modules/generatePass/redux/reducers/index";

const store = createStore(
  combineReducers({
    // Page info
    breakpoint: breakpointReducer,
    ...layoutReducer,
    ...passListReducer,
    ...passDetailsReducer,
    ...passReportReducer,
    ...passReducer,
  }),
  compose(
    applyMiddleware(thunk),
    (window && window.__REDUX_DEVTOOLS_EXTENSION__) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  )
);

export default store;
