const { getPassDetailsData, validatePassService, invalidatePassService, sendEmailService, sendSMSService, createPassService, updatePassOwnershipService, updatePassStatusDateService } = require("../services/passTicketService");
import { getFormatedDate, getPassNoFromPair, getTokenType, validatePassDate } from '../utils/helper';
const enums = require('../enums/index');

export const getPassDetails = async (params) => {
  try {
    let data = await getPassDetailsData(params);

    if (!data)
      throw "Error in getPassDetails";

    if (data && data.length > 0) {
      data = data.filter(i => i.Pass_No === params.passNo);

      if (data && data.length > 0) {
        data = data[0];
      } else {
        return {};
      }

      data = {
        status: data.Status.toLowerCase(),
        orderDetails: {
          invalidatedOn: data.Voucher_Cancel_Date,
          validatedOn: data.Date_Redeemed,
          validatedBy: data.PassvalidatedBy,
          outlet: data.Redemming_Outlet,
          orderNo: data.Order_Number,
          passNo: data.Pass_No,
          sku: data.SKU,
          price: data.Price,
          validFrom: data.Create_Date,
          expiredOn: data.Expiry_Date,
          country: data.Country,
          pdfUrl: data.HSP_PassDoc
        },
        personalDetails: {
          aboid: data.Abo_Id,
          name: data.AboName,
          //nric: data.NRIC,
          email: data.Email !== 'NA' ? data.Email : null,
          mobile: data.MobNumber !== 'NA' ? data.MobNumber : null,
        },
        disabledAction: [enums.passStatus.INVALID, enums.passStatus.USED, enums.passStatus.EXPIRED].includes(data.Status.toLowerCase()) ? [enums.actionName.INVALIDATE] : null
      }

      if (getTokenType() === 'hccpartner') {
        let orderPasses = await getPassDetailsData({ orderNumber: data.orderDetails.orderNo });
        if (data.status.toLowerCase() === enums.passStatus.NEW || data.status.toLowerCase() === enums.passStatus.EXPIRING) {
          data.enableValidate = checkEnableValidate(orderPasses, data.orderDetails.passNo, data.orderDetails.validFrom);
        }
      }
      if (getTokenType() === 'cuscare') {
        let orderPasses = await getPassDetailsData({ orderNumber: data.orderDetails.orderNo });

        let enableInvalidate = checkEnableInvalidate(orderPasses, data.orderDetails.passNo);
        if (!enableInvalidate) {
          if (data.disabledAction == null)
            data.disabledAction = [enums.actionName.INVALIDATE];
          else
            data.disabledAction.push(enums.actionName.INVALIDATE);
        }
      }

      return data;
    } else {
      return {};
    }
  } catch (err) {
    console.log(`Error in getPassDetails: ${err}`);
  }
};

export const getPassList = async (params) => {
  try {
    let data = await getPassDetailsData(params);

    if (!data)
      throw "Error in getPassList";

    const sequenceByStatus = [
      enums.passStatus.EXPIRING,
      enums.passStatus.NEW,
      enums.passStatus.USED,
      enums.passStatus.EXPIRED,
      enums.passStatus.PENDING,
      enums.passStatus.INVALID,
    ];

    if (data.length > 0) {
      // sort data
      data.sort((a, b) => (
        getFormatedDate(b.Create_Date, 'YYYYMMDD') - getFormatedDate(a.Create_Date, 'YYYYMMDD')
      ))
      data.sort((a, b) => (
        sequenceByStatus.indexOf(a.Status.toLowerCase()) - sequenceByStatus.indexOf(b.Status.toLowerCase())
      ));

      console.log('_data', data);

      // format data
      const _itemList = [];
      data.forEach(d => {
        let _disabledAction = [
          enums.passStatus.USED,
          enums.passStatus.EXPIRED,
          enums.passStatus.INVALID,
        ].includes(d.Status?.toLowerCase())
          ? [enums.actionName.INVALIDATE, enums.actionName.VALIDATE]
          : d.Status?.toLowerCase() === enums.passStatus.PENDING
            ? [
              enums.actionName.VIEW,
              enums.actionName.DOWNLOAD,
              enums.actionName.SMS,
              enums.actionName.EMAIL,
              enums.actionName.INVALIDATE,
              enums.actionName.VALIDATE
            ]
            : null;

        if (_itemList.some(i => i.orderNo === d.Order_Number)) {
          let isPairedHsp = false;
          _itemList.find(i => i.orderNo === d.Order_Number)?.passes?.map(pass => {
            if (pass[0].SKU === d.SKU && getPassNoFromPair(pass[0].passNo) === getPassNoFromPair(d.Pass_No)) {
              isPairedHsp = true;
              pass.push({
                aboId: d.Abo_Id,
                country: d.Country,
                id: d.Pass_No?.charAt(d.Pass_No.length - 1) === 'B' ? 2 : 1,
                passNo: d.Pass_No,
                status: d.Status?.toLowerCase(),
                validDate: getFormatedDate(d.Create_Date),
                expiredDate: getFormatedDate(d.Expiry_Date),
                mobileNo: d.MobNumber !== 'NA' ? d.MobNumber : null,
                email: d.Email !== 'NA' ? d.Email : null,
                SKU: d.SKU,
                price: d.Price,
                nric: d.NRIC,
                pdfUrl: d.HSP_PassDoc,
                disabledAction: _disabledAction,
              })
            }
          })
          if (!isPairedHsp) {
            _itemList.find(i => i.orderNo === d.Order_Number)?.passes?.push([
              {
                aboId: d.Abo_Id,
                country: d.Country,
                id: d.Pass_No?.charAt(d.Pass_No.length - 1) === 'B' ? 2 : 1,
                passNo: d.Pass_No,
                status: d.Status?.toLowerCase(),
                validDate: getFormatedDate(d.Create_Date),
                expiredDate: getFormatedDate(d.Expiry_Date),
                mobileNo: d.MobNumber !== 'NA' ? d.MobNumber : null,
                email: d.Email !== 'NA' ? d.Email : null,
                SKU: d.SKU,
                price: d.Price,
                nric: d.NRIC,
                pdfUrl: d.HSP_PassDoc,
                disabledAction: _disabledAction,
              },
            ])
          }
        } else {
          _itemList.push({
            orderNo: d.Order_Number,
            passes: [
              [
                {
                  aboId: d.Abo_Id,
                  country: d.Country,
                  id: d.Pass_No?.charAt(d.Pass_No.length - 1) === 'B' ? 2 : 1,
                  passNo: d.Pass_No,
                  status: d.Status?.toLowerCase(),
                  validDate: getFormatedDate(d.Create_Date),
                  expiredDate: getFormatedDate(d.Expiry_Date),
                  mobileNo: d.MobNumber !== 'NA' ? d.MobNumber : null,
                  email: d.Email !== 'NA' ? d.Email : null,
                  SKU: d.SKU,
                  price: d.Price,
                  nric: d.NRIC,
                  pdfUrl: d.HSP_PassDoc,
                  disabledAction: _disabledAction,
                },
              ],
            ],
          });
        }
      });

      _itemList.forEach((item) => {
        item.passes.forEach((pass) => {
          pass.sort((a, b) => (
            a.id - b.id
          ))
            .sort((a, b) => (
              sequenceByStatus.indexOf(a.status.toLowerCase()) - sequenceByStatus.indexOf(b.status.toLowerCase())
            ))
        })
      });

      //Check Validate action enabled, for Partner
      if (getTokenType() === 'hccpartner' && _itemList.length > 0) {
        let orderPasses = await getPassDetailsData({ orderNumber: _itemList[0].orderNo });
        _itemList.forEach(async (item) => {
          item.passes.forEach(pass => {
            pass.forEach((j) => {
              if (j.status.toLowerCase() === enums.passStatus.NEW || j.status.toLowerCase() === enums.passStatus.EXPIRING) {
                let enableValidate = checkEnableValidate(orderPasses, j.passNo, j.validDate);

                //Disable validate action
                if (!enableValidate) {
                  if (j.disabledAction == null)
                    j.disabledAction = [enums.actionName.VALIDATE];
                  else
                    j.disabledAction.push(enums.actionName.VALIDATE);
                }
              }
            });
          })
        });
      }

      //Check Invalidate action enabled, for cuscare
      if (getTokenType() === 'cuscare' && _itemList.length > 0) {
        let orderPasses = data;

        if (_itemList.length == 1) {
          orderPasses = await getPassDetailsData({ orderNumber: _itemList[0].orderNo });
        }

        _itemList.forEach(async (item) => {
          item.passes.forEach(pass => {
            pass.forEach(async (j) => {
              if (j.status.toLowerCase() === enums.passStatus.NEW || j.status.toLowerCase() === enums.passStatus.EXPIRING) {
                let enableInvalidate = checkEnableInvalidate(orderPasses, j.passNo);

                //Disable invalidate action
                if (!enableInvalidate) {
                  if (j.disabledAction == null)
                    j.disabledAction = [enums.actionName.INVALIDATE];
                  else
                    j.disabledAction.push(enums.actionName.INVALIDATE);
                }
              }
            });
          })
        });
      }

      data = _itemList;
    }

    return {
      data
    };
  } catch (err) {
    console.log(`Error in getPassList: ${err}`);
  }
};

const checkEnableValidate = (orderPasses, passNo, validFrom) => {
  let enableValidate = true;
  let lastChar = passNo.charAt(passNo.length - 1)?.toUpperCase();

  if (!validatePassDate(validFrom)) { //Today earlier than Valid Start Date 
    enableValidate = false;
  } else if ((lastChar !== 'A' && lastChar !== 'B') || lastChar === 'A') {
    enableValidate = true;
  } else {
    let mainPass = orderPasses.filter(x => getPassNoFromPair(x.Pass_No) === getPassNoFromPair(passNo) && x.Pass_No.charAt(x.Pass_No.length - 1)?.toUpperCase() === 'A')

    if (mainPass && mainPass.length > 0) {
      if (mainPass[0].Status.toLowerCase() === enums.passStatus.NEW || mainPass[0].Status.toLowerCase() === enums.passStatus.EXPIRING) {
        enableValidate = true; // HSP2 able to be validated even if HSP1 is not validated yet, as long as it’s within the validity period.
      }
    }
  }

  return enableValidate;
}

const checkEnableInvalidate = (orderPasses, passNo) => {
  let enableInvalidate = true;
  let lastChar = passNo.charAt(passNo.length - 1)?.toUpperCase();

  if ((lastChar !== 'A' && lastChar !== 'B') || lastChar === 'A') {
    enableInvalidate = true;
  } else {
    let mainPass = orderPasses.filter(x => getPassNoFromPair(x.Pass_No) === getPassNoFromPair(passNo) && x.Pass_No.charAt(x.Pass_No.length - 1)?.toUpperCase() === 'A')

    if (mainPass && mainPass.length > 0) {
      if (mainPass[0].Status.toLowerCase() === enums.passStatus.USED) { //The HSP1 of the paired pass is used
        enableInvalidate = false;
      }
    }
  }

  return enableInvalidate;
}

export const validatePass = async (params) => {
  try {
    let data = await validatePassService(params);

    data.success = data.status === 200;

    return data;
  } catch (err) {
    console.log(`Error in validatePass: ${err}`);
  }
};

export const invalidatePass = async (params) => {
  try {
    let data = await invalidatePassService(params);

    data.success = data.status === 200;

    return data;
  } catch (err) {
    console.log(`Error in invalidatePass: ${err}`);
  }
};

export const sendEmail = async (params) => {
  try {
    let data = await sendEmailService(params);

    data.success = data.status === 200;

    return data;
  } catch (err) {
    console.log(`Error in sendEmail: ${err}`);
  }
};

export const sendSMS = async (params) => {
  try {
    let data = await sendSMSService(params);

    data.success = data.status === 200;

    return data;
  } catch (err) {
    console.log(`Error in sendSMS: ${err}`);
  }
};

export const createPass = async (params) => {
  try {
    let data = await createPassService(params);

    if (data && data.length > 0) {
      data = data[0];
    }

    return data;
  } catch (err) {
    console.log(`Error in createPass: ${err}`);
  }
};

export const updatePassOwnership = async (params) => {
  try {
    let data = await updatePassOwnershipService(params);

    if (data && data.length > 0) {
      data = data[0];
    }

    return data;
  } catch (err) {
    console.log(`Error in updatePassOwnership: ${err}`);
  }
};

export const updatePassStatusDate = async (params) => {
  try {
    let data = await updatePassStatusDateService(params);

    if (data && data.length > 0) {
      data = data[0];
    }

    return data;
  } catch (err) {
    console.log(`Error in updatePassStatusDate: ${err}`);
  }
};
