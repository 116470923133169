import axios from 'axios';
import {
  getAccessToken,
  isAuthenticated,
} from '../application-center/login-center/lib/authentication';
//const helper = require('./helper');

axios.interceptors.request.use(async request => {
  const appToken = getAccessToken();

  if (appToken) {
    if (isAuthenticated()) {
      // with valid token time

      const headers = {
        Authorization: 'Bearer ' + appToken,
        'Content-Type': 'application/json',
      };

      request.headers = {
        ...request.headers,
        ...headers,
      };

      /*request.data = {
        ...request.data,
        // for future customization
      };*/

      return request;
    } else {
      window.location.href = '/';
    }
  } else {
    // for other purpose, eg: login, log, parameters
    const headers = {
      'Content-Type': 'application/json',
    };

    request.headers = {
      ...request.headers,
      ...headers,
    };

    return request;
  }
}, undefined);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async failedResponse => {
    if (failedResponse.response?.status === 401) {
      /*if (
        failedResponse.response.config.url.search('AdmSytemMessage') < 0 &&
        failedResponse.response.config.url.search('logout') < 0 &&
        failedResponse.response.config.url.search('UXTracking') < 0
      ) {
        //alert(
        //  'Unauthorized access/session killed due to multiple login, please login.',
        //);
        window.location.href = '/logout?exp=401';
      }*/

      throw new axios.Cancel();
    }
    return Promise.reject(failedResponse);
  },
);
export default axios;