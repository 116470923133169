import { getUrlByRole } from "../utils/helper";

const { default: axios } = require("axios");


export const getPassDetailsData = async (params) => {
  require("isomorphic-fetch");
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/search";
  url = getUrlByRole(url);

  let paramBody = { "search": {} };
  if (params) {
    if (params.aboNo)
      paramBody = { ...paramBody, search: { ...paramBody.search, aboid: params.aboNo } }
    if (params.nric)
      paramBody = { ...paramBody, search: { ...paramBody.search, nric: params.nric } }
    if (params.passNo)
      paramBody = { ...paramBody, search: { ...paramBody.search, pass_no: params.passNo } }
    if (params.email)
      paramBody = { ...paramBody, search: { ...paramBody.search, email: params.email } }
    if (params.mobileNo)
      paramBody = { ...paramBody, search: { ...paramBody.search, mobile: params.mobileNo } }
    if (params.orderNumber)
      paramBody = { ...paramBody, search: { ...paramBody.search, order_number: params.orderNumber } }
    if (params.sku)
      paramBody = { ...paramBody, search: { ...paramBody.search, sku: params.sku } }
    if (params.key) // else/default
      paramBody = { ...paramBody, search: { ...paramBody.search, key: params.key } }
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json',
      "Authorization": 'eyJraWQiOiJoNEFTWXFsN1g2WlJ5Z3BhdmhDM3JhZDZnZnZfZWV3eGNKSUQxclctSThFIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULi14RHZiRHhVcHRMSkZSVmhqa2JiWUVUX3l4TDlabEZtN0NQVUJmdmVFaFkiLCJpc3MiOiJodHRwczovL2Ftd2F5Lm9rdGEuY29tIiwiYXVkIjoiaHR0cHM6Ly9hbXdheS5va3RhLmNvbSIsInN1YiI6Ik1ZR0FSWUxBQG5hLmludHJhbmV0Lm1zZCIsImlhdCI6MTY4MzU5NTc3OCwiZXhwIjoxNjgzNTk5Mzc4LCJjaWQiOiIwb2ExcjZ5d3doNHN5UGdWRDBoOCIsInVpZCI6IjAwdTFwMTZndG9idkFqOUhCMGg4Iiwic2NwIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJlbWFpbCJdLCJhdXRoX3RpbWUiOjE2ODM1OTU3NzJ9.gUzX3ES5jb--Owv3gUCYWFalRn6VnefrAz6SBXgykAziMe7EXsOdj2vq5p_MvpRT_HAKdJIc9brsHlUkppZ_maUZ9tUlvlmNJrxUb-WxcEBHykDLEg-byZN47zPzkiATmfBJvt0XCg0rSUndUjlywVxfeWkVAl-eaH71Zgm7474W8GKRVsbfgllDb3ZAg3VCstvaZVI-gYIyRdBYEgyCTQg59zryoSbRXjyUQOSvNPAlbl_VRzCtFztF9FZmKrFtaiPfeXGSOrkbcBAZwDX2GaVc06162VJSvAKUS4c-ya6h-pXCtJfNtCTnLANe328FHxZrOdu1cHvBYD9BFw45oQ'
    },
  });
  data = res.data.aboiddata;

  return data;
};

export const getPassListing = async () => {
  require("isomorphic-fetch");
  var data;
  var url =
    process.env.REACT_APP_API_URL +
    "/healthscreening/listing"

  const res = await axios(url, {
    method: 'GET',
    params: {},
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data.aboiddata;

  return data;
};

export const validatePassService = async (params) => {
  require("isomorphic-fetch");
  var data;
  var url =
    process.env.REACT_APP_API_URL +
    "/healthscreening/partner/validate"

  let paramBody = {
    "validate": {
      pass_no: params.pass_no,
      aboid: params.aboid,
      // nric:"A9312",
      // mobile:"0123-4567890",
      // email:"amway@amway.com",
      redeemed_by_outlet_id: params.redeemed_by_outlet_id,
      redeemed_by_user_id: params.redeemed_by_user_id,
      pass_validated_by: params.pass_validated_by
    }
  };

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const invalidatePassService = async (params) => {
  require("isomorphic-fetch");
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/cuscare/invalidate"

  let paramBody = {
    "invalidate": {
      "aboid": params.aboid,
      "pass_no": params.passNo
    }
  };

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const sendEmailService = async (params) => {
  require("isomorphic-fetch");
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/sendemail";
  url = getUrlByRole(url);

  let paramBody = {
    "sendemail": {
      "aboid": params.aboid,
      "pass_no": params.passNo,
      "email": params.email.toLowerCase()
    }
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const sendSMSService = async (params) => {
  require("isomorphic-fetch");
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/sendsms";
  url = getUrlByRole(url);

  let paramBody = {
    "sendsms": {
      "aboid": params.aboid,
      "pass_no": params.passNo,
      "mobile": params.mobile
    }
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const createPassService = async (params) => {
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/createnew";
  url = getUrlByRole(url);

  let paramBody = {
    "CreateNewPass": [{
      aboid: params?.aboid,
      country: params?.country,
      order_number: params?.order_number,
      order_date: params?.order_date,
      sku: params?.sku,
      mobile: params?.mobile,
      email: params?.email?.toLowerCase(),
    }]
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const updatePassOwnershipService = async (params) => {
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/regenerate";
  url = getUrlByRole(url);

  let paramBody = {
    "Regenerate": [{
      pass_no_old: params?.pass_no_old,
      aboid_new: params?.aboid_new,
      mobile_new: params?.mobile_new,
      email_address_new: params?.email_address_new?.toLowerCase(),
    }]
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}

export const updatePassStatusDateService = async (params) => {
  var data;
  var url = process.env.REACT_APP_API_URL + "/healthscreening/{ROLE}/updatestatusdate";
  url = getUrlByRole(url);

  let paramBody = {
    "UpdatePassStatusDate": [{
      pass_no: params?.pass_no,
      pass_status_new: params?.pass_status_new?.toLowerCase(),
      dt_create_new: params?.start_date_new,
      dt_expire_new: params?.expiry_date_new,
    }]
  }

  const res = await axios(url, {
    method: 'POST',
    data: paramBody,
    headers: {
      'Content-type': 'application/json'
    },
  });
  data = res.data;

  return data;
}
