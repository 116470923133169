import React from 'react';
import AuthPage from './application-center/login-center';

export const LoginCenter = AuthPage;

export const UnderConstruction = React.lazy(() => import('./components/UnderConstruction'));

export const aboApcDashboardPage = React.lazy(() => import('./modules/BGHAbo'));
export const cusCareDashboardPage = React.lazy(() => import('./modules/BGHAcc'));
export const hccPartnerHomePage = React.lazy(() => import('./modules/BGHPartner'));

export const aboApcLandingPage = React.lazy(() => import('./modules/BGHAbo/login/landing'));
export const cusCareLandingPage = React.lazy(() => import('./modules/BGHAcc/login/landing'));
export const cusCareLocalHostLandingPage = React.lazy(() => import('./modules/BGHAcc/login/lohalHostLanding'));
export const hccPartnerLandingPage = React.lazy(() => import('./modules/BGHPartner/login/landing'));

export const aboApcRoutes = [
  {
    path: '/viewPass',
    component: React.lazy(() => import('./modules/BGHViewPass')),
  },
];

export const cusCareRoutes = [
  {
    path: '/generatePass',
    component: React.lazy(() => import('./modules/generatePass')),
  },
  {
    path: '/viewPass',
    component: React.lazy(() => import('./modules/BGHViewPass')),
  },
];

export const hccPartnerRoutes = [
  {
    path: '/passreport',
    component: React.lazy(() => import('./modules/passReport')),
  },
  {
    path: '/viewPass',
    component: React.lazy(() => import('./modules/BGHViewPass')),
  },
];
