import React from "react";
import NewSpinner from "../newSpinner/newSpinner";
import Portal from "../portal/portal";
import "./fullPageLoader.scss";

function FullPageLoader() {
  return (
    <Portal>
      <div className={"fullpage_loader"}>
        <NewSpinner size="md" borderColor="#2c2c2c" borderTopColor="white" />
      </div>
    </Portal>
  );
}

export default FullPageLoader;
