const { getPartnerLoginData } = require("../services/loginService");

export const getPartnerLogin = async (params) => {
  try {
    let data = await getPartnerLoginData(params);

    if (data && data.length > 0) {
      data = data[0];
    }

    data = {
      userId: data?.user,
      outletId: data?.Outlet_Id,
      token: data?.token,
      permission: data?.emp_permission
    }

    return data;
  } catch (err) {
    console.log(`Error in getPartnerLogin: ${err}`);
  }
};
